import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { conf } from "../../settings";
import { LoaderFullPage, Page, Section } from "../../components";
import { Log } from "../../components/LogMessage";
import { log, delay } from "../../lib/utils";
import { apisEc2 } from "./Apis-batch-test-apis";

export default function ApiBatchTest(props) {
  const title = "API 연동 배치 테스트";
  const delayTime = 500; // in msec

  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");

  /**
   * @array_map_은_거의_동시에_콜백함수를_수행하므로_콜백함수내의_setTimeout_의_delay를_다르게_주어야_한다
   */
  const testApis = async (apis) => {
    try {
      setIsLoading(true)
      await Promise.all(apis.map(async (item, i) => {
        await delay(1000 * i);
        let res = await item.api(item.fields);
        let logMsg;
        if (res.err) logMsg = createLine(item.apiName, res.status, res.err.message);
        else logMsg = createLine(item.apiName, res.status);

        setMsg((pre) => logMsg + pre);
        log(item.apiName, res.status);
        // if (i === (apisEc2.length - 1)) setIsLoading(false);
      }));
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * @for_loop는_순차적으로_코드를_수행하므로_delay_time이_같아도_된다 
   */
  const loopApis = async (apis) => {
    try {
      if (apis.length === 0) throw Error("API를 먼저 등록해주세요");
      setIsLoading(true);

      for (let i = 0; i < apis.length; i++) {
        let item = apisEc2[i];
        await delay(delayTime);

        let res = await item.api(item.fields);
        let logMsg;
        if (res.err) logMsg = createLine(item.apiName, res.status, res.err.message);
        else logMsg = createLine(item.apiName, res.status);

        setMsg((pre) => logMsg + pre);
        console.log(item.apiName, res);
      }

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const createLine = (apiName, data, msg) => {
    let ts = Date.now();
    // console.log(new Date(ts).toLocaleTimeString());
    return `
      <div style="display: flex; justify-content: space-between; width: 100%">
        <p style="flex: 1">${new Date(ts).toLocaleTimeString()}</p>
        <p style="flex: 1">${apiName}</p>
        <p style="flex: 1">${data}</p>
        <p style="flex: 2">${msg === undefined ? "" : msg}</p>
      </div>
    `
  }

  return (
    <Page title={title} stretch={true}>
      <Section>
        <Button onClick={() => loopApis(apisEc2)} style={{ width: "100%", marginBottom: 10 }} >
          Start Testing EC2 Apis  - endpoint: {conf.ec2Debug ? "http://localhost:5000" : conf.ec2ApiEndpoint}
        </Button>       
      </Section>

      <Section>
        <Log msg={msg} />
      </Section>

      {isLoading && <LoaderFullPage />}

    </Page>
  );
}
