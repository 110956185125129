import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage, Table, colors } from "../../components";
import { Plus } from "../../components2/Icons";
import SetCategoryModal from "./SetCategoryModal";
import { categoryList } from "../../lib/ec2-api-cms-lib";

const header = [
  { name: "group", displayName: "그룹" },
  { name: "category", displayName: "카테고리" },
  { name: "description", displayName: "설명" },
  { name: "created", displayName: "생성일자" },
]

let cnt = 0;

export default function Categories(props) {
  const title = "블로그 카테고리";
  const [isLoading, setIsLoading] = useState(true)
  const [tableItems, setTableItems] = useState([])
  const [visibleCat, setVisibleCat] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cat, setCat] = useState({});

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);

      let res = await categoryList({ group: "all" });
      if (res.err) throw Error(res.err.message)
      setTableItems(res.result.Items)

    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = { onload, cat, isEdit }

  const onSelect = (obj) => {
    setIsEdit(true)
    setCat(obj)
    setVisibleCat(true)
  }

  const onClickPlus = () => {
    setIsEdit(false)
    setCat({})
    setVisibleCat(true)
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <h3 style={{ margin: 5, padding: 0 }}>
          <Plus onClick={onClickPlus} style={{ color: colors.primary }} />
        </h3>
        <Table
          {...props}
          tableHeader={header}
          rowItems={tableItems}
          onClickItem={onSelect}
        />
        {
          (!isLoading && tableItems.length === 0) &&
          <>
            <h5 style={{ textAlign: "center" }}>등록된 항목이 없습니다.</h5>
            <h5 style={{ textAlign: "center" }}>상단의 + 를 클릭하여 카테고리를 추가하세요!</h5>
          </>
        }
      </Section>

      {isLoading && <LoaderFullPage />}

      {
        !isLoading &&
        <SetCategoryModal
          key={"cat"+cnt++}
          {...props}
          {...otherProps}
          visible={visibleCat}
          setIsVisible={setVisibleCat}
        />
      }
    </Page>

  )
}