import { prod } from "./awsConfig";
import { 
  appName, 
  stage, 
  debug, ec2Debug, authDebug,
  apiKey,
  ec2ApiEndpoint
} from "./appSetting";

const awsConfig = prod;

export default {
  stage, debug, ec2Debug, authDebug,
  ec2ApiEndpoint,
  appName, apiKey,
  ...awsConfig,
  MAX_FILE_SIZE: 1000000, // 1MB
  superAdminPhoneNo: "01053174673"
}