import axios from "axios";
import { debug, conf } from "../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = conf.ec2Debug
      ? "http://localhost:5000"
      : conf.ec2ApiEndpoint;

    if (debug) console.log('baseUrl', baseUrl);

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    // if (debug) console.log('res - api ', res);
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** @Brand_Auth_Demo ***************************************************/

// ads
export const baAdsCreate = async ({ adGroup, adName, body }) => {
  let path = `/ba/ads?adGroup=${adGroup}&adName=${adName}`;
  return await axiosCall({ method: "POST", path, body });
};
export const baAdsGet = async ({ adGroup, adName }) => {
  let path = `/ba/ads?adGroup=${adGroup}&adName=${adName}`;
  return await axiosCall({ method: "GET", path });
};
export const baAdsList = async ({ adGroup }) => {
  // baAdsList==="all" 이면 전체를 가져옴
  let path = `/ba/ads/list?adGroup=${adGroup}`;
  return await axiosCall({ method: "GET", path });
};
export const baAdsUpdate = async ({ adGroup, adName, body }) => {
  let path = `/ba/ads?adGroup=${adGroup}&adName=${adName}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const baAdsDelete = async ({ adGroup, adName }) => {
  let path = `/ba/ads?adGroup=${adGroup}&adName=${adName}`;
  return await axiosCall({ method: "DELETE", path });
};

// products
export const baProductCreate = async ({ company, productName, body }) => {
  let path = `/ba/product?company=${company}&productName=${productName}`;
  return await axiosCall({ method: "POST", path, body });
};
export const baProductGet = async ({ company, productName }) => {
  let path = `/ba/product?company=${company}&productName=${productName}`;
  return await axiosCall({ method: "GET", path });
};
export const baProductList = async ({ company }) => {
  // company==="all" 이면 전체를 가져옴
  let path = `/ba/product/list?company=${company}`;
  return await axiosCall({ method: "GET", path });
};
export const baProductUpdate = async ({ company, productName, body }) => {
  let path = `/ba/product?company=${company}&productName=${productName}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const baProductDelete = async ({ company, productName }) => {
  let path = `/ba/product?company=${company}&productName=${productName}`;
  return await axiosCall({ method: "DELETE", path });
};


// nfctags
export const baTagCreate = async ({ ver, uid, body }) => {
  let path = `/ba/tag?ver=${ver}&uid=${uid}`;
  return await axiosCall({ method: "POST", path, body });
};
export const baTagGet = async ({ ver, uid }) => {
  let path = `/ba/tag?ver=${ver}&uid=${uid}`;
  return await axiosCall({ method: "GET", path });
};
export const baTagUpdate = async ({ ver, uid, body }) => {
  let path = `/ba/tag?ver=${ver}&uid=${uid}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const baTagDelete = async ({ ver, uid }) => {
  let path = `/ba/tag?ver=${ver}&uid=${uid}`;
  return await axiosCall({ method: "DELETE", path });
};
export const baTagAuth = async ({ ver, uid, cnt, hotp }) => {
  let path = `/ba/tag/auth/?ver=${ver}&uid=${uid}&cnt=${cnt}&hotp=${hotp}`;
  return await axiosCall({ method: "GET", path });
};