import axios from "axios";
import { debug, conf } from "../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = conf.ec2Debug
      // ? "http://localhost:5000"
      ? "http://172.30.1.27:5000"
      : conf.ec2ApiEndpoint;

    // if (debug) console.log('baseUrl', baseUrl);

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    // if (debug) console.log('res - api ', res);
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/** @Misc */
export const sendEmail = async ({ body }) => {
  const path = `/misc/send-email`;
  // body: {senderInfo, contactInfo, msg}
  return await axiosCall({ method: "POST", path, body });
};
export const sendSms = async ({ phone_number }) => {
  const path = `/misc/sms?phone_number=${phone_number}`;
  return await axiosCall({ method: "GET", path });
};
export const getErrLogs = async ({ shopId, start_time, end_time }) => {
  const path = `/misc/err-log?shopId=${shopId}&start_time=${start_time}&end_time=${end_time}`;
  return await axiosCall({ method: "GET", path });
};
export const getOrderRecords = async ({ shopId, start_time, end_time }) => {
  const path = `/misc/order-records?shopId=${shopId}&start_time=${start_time}&end_time=${end_time}`;
  return await axiosCall({ method: "GET", path });
};

/** @user */
export const userCreate = async ({ username, body }) => {
  // body = {userType, phone_number, email, nickName, approved=false}
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "POST", path, body });
};
export const userGet = async ({ username }) => {
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "GET", path });
};
export const userList = async ({ userType }) => {
  const path = `/user/list?userType=${userType}`;
  return await axiosCall({ method: "GET", path });
};
export const userListByPhoneNo = async ({ phone_number }) => {
  const path = `/user/list?phone_number=${phone_number}`;
  return await axiosCall({ method: "GET", path });
};
export const userUpdate = async ({ username, body }) => {
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const userDelete = async ({ username }) => {
  const path = `/user?username=${username}`;
  return await axiosCall({ method: "DELETE", path });
};
export const userAttachPolicy = async ({ username, body }) => {
  const path = `/user/attach-policy?username=${username}`;
  return await axiosCall({ method: "POST", path, body });
};

/** @cognito */
export const isExistUsername = async ({ username }) => {
  const path = `/user/cognito?username=${username}`;
  return await axiosCall({ method: "GET", path });
};
export const cognitoListUsers = async ({ phone_number }) => {
  const path = `/user/cognito/list-users?phone_number=${phone_number}`;
  return await axiosCall({ method: "GET", path });
};
export const changePassword = async ({ username, password }) => {
  const path = `/user/cognito/changepw?username=${username}&password=${password}`;
  return await axiosCall({ method: "POST", path });
};

/** @shop */
export const shopCreate = async ({ shopId, body }) => {
  const path = `/shop?shopId=${shopId}`;
  return await axiosCall({ method: "POST", path, body });
};
export const shopGet = async ({ shopId }) => {
  const path = `/shop?shopId=${shopId}`;
  // const path = `/shop/${shopId}`; // deprecated
  return await axiosCall({ method: "GET", path });
};
export const shopUpdate = async ({ shopId, body }) => {
  const path = `/shop?shopId=${shopId}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const shopDelete = async ({ shopId }) => {
  const path = `/shop?shopId=${shopId}`;
  return await axiosCall({ method: "DELETE", path });
};
/**
 * @param {Object} param.query query = storeCode, licenseNo, shopName 중 하나의 퀘리만 허용함.
 * @returns 
 */
export const shopList = async ({ query }) => {
  try {

    let path = `/shop/list`;
    if (typeof query !== "object") throw Error("INVALID QUERY");
    let names = Object.keys(query);
    let qstring = "?";
    for (let i = 0; i < names.length; i++) {
      qstring += `${names[i]}=${query[names[i]]}`;
      if (i < (names.length - 1)) qstring += "&";
    }
    path += qstring;

    return await axiosCall({ method: "GET", path });
  } catch (e) {
    return { err: { message: e.message } };
  }
};
export const shopListByPeriod = async ({ start_time, end_time }) => {
  let path = `/shop/list/period?start_time=${start_time}&end_time=${end_time}`;
  return await axiosCall({ method: "GET", path });
};
export const checkShopExist = async ({ posname, storeCode }) => {
  const path = `/shop/${posname}/check-shop?storeCode=${storeCode}`;
  return await axiosCall({ method: "GET", path });
};

/** @shopItems */
export const shopItemCreate = ({ shopId, body, id }) => {
  let path = `/shopitem/${shopId}`;
  if (id) path += `?id=${id}`;
  return axiosCall({ method: "POST", path, body });
};
export const shopItemGet = ({ shopId, id }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return axiosCall({ method: "GET", path });
};
export const shopItemUpdate = ({ shopId, id, body }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return axiosCall({ method: "PUT", path, body });
};
export const shopItemDelete = async ({ shopId, id }) => {
  const path = `/shopitem/${shopId}?id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};
export const shopItemList = async ({ shopId, token }) => {
  let path = `/shopitem/${shopId}/list/auth?token=${token}`;
  // if (debug) path += '&mode=debug';
  path += '&mode=debug';
  return await axiosCall({ method: "GET", path });
};

export const okposItemCreate = ({ shopId, body, id }) => {
  let path = `/shopitem/okpos/${shopId}`;
  if (id) path += `?id=${id}`;
  return axiosCall({ method: "POST", path, body });
};
export const okposItemGet = ({ shopId, id }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return axiosCall({ method: "GET", path });
};
export const okposItemUpdate = ({ shopId, id, body }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return axiosCall({ method: "PUT", path, body });
};
export const okposItemDelete = async ({ shopId, id }) => {
  const path = `/shopitem/okpos/${shopId}?id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};
export const okposItemList = async ({ shopId, token }) => {
  let path = `/shopitem/okpos/${shopId}/list/auth?token=${token}`;
  // if (debug) path += '&mode=debug';
  path += '&mode=debug';
  return await axiosCall({ method: "GET", path });
};

/** @shopItemOptions */
export const shopItemOptionCreate = ({ shopId, optionName, body }) => {
  let path = `/shopitem-option/${shopId}`;
  if (optionName) path += `?optionName=${optionName}`;
  return axiosCall({ method: "POST", path, body });
};
export const shopItemOptionGet = ({ shopId, optionName }) => {
  let path = `/shopitem-option/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "GET", path });
};
export const shopItemOptionList = ({ shopId }) => {
  let path = `/shopitem-option/${shopId}/list`;
  return axiosCall({ method: "GET", path });
};
export const shopItemOptionUpdate = ({ shopId, optionName, body }) => {
  const path = `/shopitem-option/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "PUT", path, body });
};
export const shopItemOptionDelete = ({ shopId, optionName }) => {
  const path = `/shopitem-option/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "DELETE", path });
};

export const okposItemOptionCreate = ({ shopId, optionName, body }) => {
  let path = `/shopitem-option/okpos/${shopId}`;
  if (optionName) path += `?optionName=${optionName}`;
  return axiosCall({ method: "POST", path, body });
};
export const okposItemOptionGet = ({ shopId, optionName }) => {
  let path = `/shopitem-option/okpos/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "GET", path });
};
export const okposItemOptionList = ({ shopId }) => {
  let path = `/shopitem-option/okpos/${shopId}/list`;
  return axiosCall({ method: "GET", path });
};
export const okposItemOptionUpdate = ({ shopId, optionName, body }) => {
  const path = `/shopitem-option/okpos/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "PUT", path, body });
};
export const okposItemOptionDelete = ({ shopId, optionName }) => {
  const path = `/shopitem-option/okpos/${shopId}?optionName=${optionName}`;
  return axiosCall({ method: "DELETE", path });
};


/** @NFC_TAG_Versions */
export const nfctagVersionCreate = ({ docVersion, tagVersion, body }) => {
  let path = `/nfctag/version?docVersion=${docVersion}`;
  if (tagVersion) path += `&tagVersion=${tagVersion}`;
  return axiosCall({ method: "POST", path, body });
};
export const nfctagVersionGet = ({ docVersion, tagVersion }) => {
  let path = `/nfctag/version?docVersion=${docVersion}&tagVersion=${tagVersion}`;
  return axiosCall({ method: "GET", path });
};
export const nfctagVersionUpdate = ({ docVersion, tagVersion, body }) => {
  let path = `/nfctag/version?docVersion=${docVersion}&tagVersion=${tagVersion}`;
  return axiosCall({ method: "PUT", path, body });
};
export const nfctagVersionDelete = ({ docVersion, tagVersion }) => {
  let path = `/nfctag/version?docVersion=${docVersion}&tagVersion=${tagVersion}`;
  return axiosCall({ method: "DELETE", path });
};
export const nfctagVersionList = ({ docVersion }) => {
  let path = `/nfctag/version/list?docVersion=${docVersion}`;
  return axiosCall({ method: "GET", path });
};


/** @NTAG424 ***********************************************************/
export const ntag424Enc = ({ body }) => {
  const path = "/nfctag/ntag424/enc";
  return axiosCall({ method: "POST", path, body });
};
export const ntag424Dec = ({ body }) => {
  const path = "/nfctag/ntag424/dec";
  return axiosCall({ method: "POST", path, body });
};
export const ntag424Cmac = ({ body }) => {
  const path = "/nfctag/ntag424/cmac";
  return axiosCall({ method: "POST", path, body });
};
export const ntag424Sesskeys = ({ body }) => {
  const path = "/nfctag/ntag424/sesskeys";
  return axiosCall({ method: "POST", path, body });
};
export const ntag424VerifyPiccData = async ({ body }) => {
  // body = {ver, piccdata, tableNo, mac}
  const path = "/nfctag/ntag424/verify-piccdata";
  return await axiosCall({ method: "POST", path, body });
};
export const ntag424VerifyToken = async ({ body }) => {
  // body= { token, tableNo}
  const path = "/nfctag/ntag424/verify-token";
  return await axiosCall({ method: "POST", path, body });
};

/** @Auth ***************************************************************/
export const authEnc = ({ ver, body }) => {
  // 
  // body = { data } data=shopId
  // return {result: {token}}
  // 
  const path = `/auth/${ver}/enc`;
  return axiosCall({ method: "POST", path, body });
};
export const authVerify = ({ ver, body }) => {
  // 
  // body = { data, shopId }
  // return { result: { token } }
  // 
  const path = `/auth/${ver}/verify`;
  return axiosCall({ method: "POST", path, body });
};
export const authVerifyToken = ({ ver, body }) => {
  // 
  // body = { token }
  // return { result: { tokenVerified, totenData, time}, [, err] }
  // 
  const path = `/auth/verify-token`;
  return axiosCall({ method: "POST", path, body });
};

/** 
 * @POS_Unionpos 
 * 
 * apiName:
 *    shopInfo | groupList | itemList | soldoutList | 
 *    imageList | tableList | descriptionList | order |
 *    tableCall
 */
export const unionposProxy2 = async ({ apiName, body }) => {
  let path = `/pos/unionpos?apiName=${apiName}`;
  // if (debug) path += '&mode=debug';
  path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};
/**
 * 
 * @param {*} body.shopId
 * @param {*} body.imageUrl
 * @returns 
 */
export const unionposCopyImage = async ({ body }) => {
  let path = `/pos/unionpos/copy-image`;
  return await axiosCall({ method: "POST", path, body });
};


/** @deprecated */
export const unionposProxy = async ({ apiName, body }) => {
  let path = `/unionpos?apiName=${apiName}`;
  if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

/**
 * @POS_SMARTRO
 * order: '/partner/v1/table/order/new',     // 신규주문
 * orderAdd: '/partner/v1/table/order/add',  // 추가주문
 * viewOrders: '/partner/v1/table/order',    // 실시간 주문조회
 * isMenuUpdated: '/partner/v1/menu/state',  // 상품정보 변경 조회
 * updatedMenu: '/partner/v1/menu/edit',     // 변경 상품정보 조회 - 전체 메뉴가 리턴됨
 * tableList: '/partner/v1/table',           // 테이블 목록 조회 
 */
export const smileposProxy = async ({ apiName, body }) => {
  let path = `/pos/smilepos?apiName=${apiName}`;
  if (debug) path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

/** @OKPos **********************************************/
export const okposProxy = async ({ apiName, body }) => {
  // 
  // menuUpdate | storeInfo | setPolicy | order | orderInfo
  // 
  let path = `/pos/okpos?apiName=${apiName}`;
  // if (debug) path += '&mode=debug';
  path += '&mode=debug';
  return await axiosCall({ method: "POST", path, body });
};

/** @DummyPos_OrderStatus **********************************************/
export const dummyposAddOrder = async ({ shopId, body }) => {
  //
  // body = orderData = {shopId, TableCode, OrderData=[], OrderQty, StoreCode}
  //  OrderData 예 = [{ ItemCode: "00017", Qty: "1", Price: "12000", IsPack: "C" },...]
  //
  const path = `/pos/dummypos/order-status/order?shopId=${shopId}`;
  return await axiosCall({ method: "POST", path, body });
};
export const dummyposUpdateOrderByOrderId = async ({ shopId, orderId, orderStatus = "A" }) => {
  let path = `/pos/dummypos/order-status/order?shopId=${shopId}`;
  path += `&orderId=${orderId}&orderStatus=${orderStatus}`;
  return await axiosCall({ method: "PUT", path, body: {} });
};
export const dummyposGetOrderList = async ({ shopId, lastModified }) => {
  const path = `/pos/dummypos/order-status?shopId=${shopId}&lastModified=${lastModified}`;
  return await axiosCall({ method: "GET", path });
};
export const dummyposGetOrderListByTable = async ({ shopId, tableNo }) => {
  const path = `/pos/dummypos/order-status/table?shopId=${shopId}&tableNo=${tableNo}`;
  return await axiosCall({ method: "GET", path });
};
export const dummyposDelete = async ({ shopId }) => { // delete orderList - test only
  const path = `/pos/dummypos/order-status?shopId=${shopId}`;
  return await axiosCall({ method: "DELETE", path });
};
