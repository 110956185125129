import { debug } from "../index"

const storeAppCatList = [
  "사용자 매뉴얼"
]

export const cmsCategory = {
  inputFields: [
    {
      name: 'group',
      displayName: "카테고리 그룹",
      // inputType: "select",
      // selectList: groupList,
      editable: false,
      defaultValue: "no-category"
    },
    {
      name: 'category',
      displayName: "카테고리 명",
      editable: false,
      comment: "포스트의 카테고리"
    },
    {
      name: 'description',
      displayName: "카테고리 설명",
    },
  ]
}

export const cmsPost = {
  inputFields: [
    {
      name: 'category',
      displayName: "포스트 카테고리명",
      inputType: "select",
      selectList: [],
    },
    {
      name: 'title',
      displayName: "포스트 타이틀",
      defaultValue: debug ? "Posting Example" : ""
    },
    {
      name: 'authorEmail',
      displayName: "작성자 이메일",
      defaultValue: debug ? "kebin@example.com" : "",
      required: false
    },
    {
      name: 'authorName',
      displayName: "작성자 이름",
      defaultValue: debug ? "Kebin Lee" : "",
      required: false
    },
    {
      name: 'excerpt',
      displayName: "요약",
      defaultValue: debug ? "스토어 앱 사용 매뉴얼" : "",
      required: false
    },
    // { name: 'htmlContent', displayName: "HTML 컨텐츠" },
    // {
    //   name: 'mdContent', 
    //   displayName: "마크다운 컨텐트",
    //   inputType: "textarea",
    //   defaultValue: ""
    // },
    {
      name: 'publish',
      inputType: 'checkbox',
      displayName: "퍼블리시",
      required: false
    },
    {
      name: 'image',
      displayName: "헤더 이미지",
      inputType: "file",
      required: false,
      defaultValue: ""
    },
  ]
}
