import React, { useEffect, useState } from "react";
import { Page, Section } from "../../components";
import { debug } from "../../settings";
import { Pencil, Trash, ArrowL } from "../../components2/Icons";
import qs from "qs";
import PostModal from "./PostModal";
import { postGet, categoryList, postDelete } from "../../lib/ec2-api-cms-lib";
import { ModalForm, FormInput, LoaderFullPage, colors, Button } from "../../components2";
import { useInputFields } from "../../lib/hooksLib";

import "./PostView.css"

export default function PostView(props) {
  // const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [post, setPost] = useState({});
  const [catList, setCatList] = useState([])
  // const [titleImage, setTitleImage] = useState("")

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      const q = qs.parse(window.location.search.split('?')[1]);

      if (debug) console.log('q', q)

      let res = await postGet({ category: q.category, id: q.id });
      if (res.err) throw Error(res.err.message)
      const p = res.result.Item
      setPost(p)
      console.log(p)

      res = await categoryList({ group: "all" });
      if (res.err) throw Error(res.err.message)
      setCatList(res.result.Items)
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = { onload, post, isEdit: true, catList }

  const styles = {
    lines: { marginBottom: 5, color: '#fff' },
    header: {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      color: post.image ? "white" : colors.dark,
      padding: "30px 25px",
      textAalign: "center",
      // backgroundColor: colors.secondary,
      // backgroundImage: `url(${post.image})`,
    },
  }

  if (post.image) styles.header["backgroundImage"] = `url(${post.image})`
  else styles.header["backgroundColor"] = colors.secondary

  return (
    <Page stretch title={post.title || "..."}>

      {
        !isLoading &&
        <Section bgColor="#fff">
          <h5 style={{ margin: 5, padding: 0 }}>
            <ArrowL onClick={() => props.history.goBack()} style={{ color: colors.dark, fontSize: "40px" }} />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Pencil onClick={() => setVisibleModal(true)} style={{ color: colors.primary }} />
            &nbsp;&nbsp;
            <Trash onClick={() => setVisibleDeleteModal(true)} style={{ color: colors.secondary }} />
          </h5>
          <hr />

          <div style={styles.header}>
            <p style={styles.lines}>작성일자 : {new Date(post.created).toLocaleDateString()}</p>
            <p style={styles.lines}>수정일자 : {new Date(post.lastModified).toLocaleDateString()}</p>
            <p style={styles.lines}>작성자 : {post.authorName}</p>
            <p style={styles.lines}>게시중 : {post.publish ? "Yes" : "No"}</p>
          </div>
          
          <div dangerouslySetInnerHTML={createMarkup(post.htmlContent)} className="onl-post" />
        </Section>
      }

      {isLoading && <LoaderFullPage />}

      {visibleModal &&
        <PostModal
          // key={"post" + cnt++}
          {...props}
          {...otherProps}
          visible={visibleModal}
          setIsVisible={setVisibleModal}
        />
      }
      {visibleDeleteModal &&
        <PostDeleteConfirmModal
          {...props}
          post={post}
          visible={visibleDeleteModal}
          setIsVisible={setVisibleDeleteModal}
        />
      }
    </Page>

  )
}

function createMarkup(html) {
  return { __html: html };
}

function PostDeleteConfirmModal(props) {
  const { visible, setIsVisible, post } = props;
  const cText = "삭제";
  // const [isLoading, setIsLoading] = useState(false)
  const [field, setField] = useInputFields({
    confirmText: ""
  })

  const onSubmit = async () => {
    try {
      // setIsLoading(true)
      if (field.confirmText !== cText) throw Error("확인 메시지가 다릅니다.")
      let res = await postDelete({ category: post.category, id: post.id });
      if (res.err) throw Error(res.err.message)
      // setIsLoading(false)
      props.history.goBack()
    } catch (e) {
      alert(e.message)
      setIsVisible(false);
    }
  }

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title="포스트 삭제">
      <p>"삭제"를 입력후 확인을 누르면 삭제가 됩니다.</p>
      <FormInput
        name="confirmText"
        value={field.confirmText}
        onChange={setField}
      />
      <Button
        size="sm"
        variant="primary"
        width="100%"
        title="확인"
        onClick={onSubmit}
      />
    </ModalForm>
  )
}