import React, { useState } from "react";
import { getTimestampFromNow } from "../../../lib/utils";
import { useStyles } from "../../../components2";
import { Section } from "../../../components";
import SearchStore from "./SearchStore";
import { CalendarDropdown } from "../../../components2/CalendarInput";

export default function SearchStoreWithCalendar(props) {
  const {
    onSelectShop,
    days = 7,         // default 기간: 오늘 포함해서 7일 
  } = props;

  const { gStyle } = useStyles()

  // const testPno = "01083448080" // 포베이 PSDL점
  // const testPno = "01055545275" // 포베이 PSDL점
  // const [searchText, setSearchText] = useState(debug ? testPno : "");
  // const [visible, setVisible] = useState(false)

  const today = new Date()
  const _startDate = new Date(getTimestampFromNow({ now: new Date(), days: -(days - 1) }))
  const [startDate, setStartDate] = useState(_startDate)
  const [endDate, setEndDate] = useState(today)

  const onSelectStore = (shop) => {
    onSelectShop({ item: shop, startDate, endDate })
  }

  const styles = {
    wrap: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center"
      // justifyContent: "space-around"
    },
  }

  return (
    <Section bgColor="#fff">
      <SearchStore {...props} onSelectStore={onSelectStore} />

      <div style={styles.wrap} >
        <CalendarDropdown
          label="Start Date"
          date={startDate}
          setDate={setStartDate}
        />

        <CalendarDropdown
          label="End Date"
          date={endDate}
          setDate={setEndDate}
        />
      </div>
    </Section>
  )
}
