import React, { useEffect, useState } from "react";
import { Page, Search, Section, Table, LoaderFullPage } from "../../components";
import { debug, strings } from "../../settings";
import { baProductList } from "../../lib/ec2-api-ba-lib";

const str = strings.baProduct;

const header = [
  { name: "company", displayName: "회사명" },
  { name: "productName", displayName: "제품명" },
  { name: "adGroup", displayName: "광고그룹명" },
  { name: "ver", displayName: "인증 버전" },
  { name: "lastModified", displayName: "수정일자" },
]

let cnt = 0;
export default function BaProducts(props) {
  const title = str.title;
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableItems, setTableItems] = useState([]);  

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      // let res = await baProductList({ company: "스타벅스" });
      let res = await baProductList({company: "all"});
      if (res.err) throw new Error(str.errMsg.fetchError);

      setTableItems(res.result.Items);
    } catch (e) {
      alert(str.fetchError + "\n" + e.message);
    } finally { setIsLoading(false) }
  }

  const onClickSearch = async () => {
    try {
      setIsLoading(true);
    } catch (e) {
      if (debug) console.log(e);
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const onSelect = (obj) => {
    if (debug) console.log(obj);
    props.history.push(`/ba/products/edit?company=${obj.company}&productName=${obj.productName}`);
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <Search
          onChange={e => setSearchText(e.target.value)}
          placeholder="회사명..."
          btnDisabled
          onClick={onClickSearch}
          onKeyUp={() => console.log('Keyup')}
          value={searchText}
        />

        <Table
          key={cnt++}
          {...props}
          tableHeader={header}
          rowItems={tableItems}
          rowItemKey="company"
          onClickItem={onSelect}
        />
      </Section>
      {isLoading && <LoaderFullPage />}
    </Page>
  );
}
