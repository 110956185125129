import React, { useState, useEffect } from "react";
import { useStyles, ModalForm, FileInputMd } from "../../components2";
import PostFormSection from "../../components2/PostFormSection";
import { debug } from "../../settings";
import { postCreate, postUpdate, postDelete } from "../../lib/ec2-api-cms-lib";
import { cmsPost } from "../../settings/cms-db";
import { fileUpload } from "../../lib/apiLib";
import { imageCompress } from "../../lib/utils";
import { useSelector } from "react-redux";
import { md2html } from "../../lib/md-utils";

export default function PostModal(props) {
  const { setIsVisible, visible, onload, post, isEdit, catList } = props;
  const title = isEdit ? "포스트 수정" : "블로그 포스팅";

  const { user } = useSelector((state) => state.user);
  const _inputFields = cmsPost.inputFields

  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const categoryList = [];
  catList.map(cat => {
    if (!categoryList.includes(cat)) categoryList.push(cat.category);
    return null;
  })

  // 
  // category.selectList 에 catList를 추가함.
  // 
  const inputFields = updateInputFields(_inputFields, categoryList)

  if (debug) {
    console.log("post", post)
    // console.log("isEdit", isEdit)
    // console.log("catList", categoryList)
  }

  const [mdText, setMdText] = useState("");
  const [htmlText, setHtmlText] = useState("")

  const file = {};

  useEffect(()=>{
    if(isEdit) {
      if(post.mdContent) setMdText(post.mdContent)
      if(post.htmlContent) setHtmlText(post.htmlContent)
    }
  },[])

  const onChangeInputFileMdFile = (e) => {
    file.currentMd = e.target.files[0];
    if (debug) console.log("file.currentMd", file.currentMd);
    let reader = new FileReader()
    reader.onload = () => {
      setMdText(reader.result)
      
      // 
      // 결과 값이 VFile object이다.
      // 
      const htmlObj = md2html(reader.result)
      setHtmlText(htmlObj.value)
    }
    reader.readAsText(file.currentMd)
  }

  const onChangeInputFileImage = (e) => {
    file.current = e.target.files[0];
    // if (debug) console.log("file.current", file.current);
  }

  const onSubmit = async (fields) => {
    try {
      setIsLoading(true);

      if (!isEdit && mdText === "") throw Error("MD File을 선택하세요!");

      if (debug) {
        console.log('id', user.id)
        console.log('\nmdText\n', mdText)
        console.log('\nhtmlText\n', htmlText)
      }

      let fileRes = {};
      if (file.current) {

        // 
        // 메뉴 이미지 저장 300KB 초과하면 resize
        // 
        let resizedImg = file.current;
        if (file.current.size > 300000) {
          resizedImg = await imageCompress({
            image: file.current,
            maxSizeMB: 0.3,
            maxWidthOrHeight: 640
          })
          if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");
        }

        fileRes = await fileUpload(resizedImg, user.id);
        if (fileRes.err) throw new Error("Error in fileUpload!");

        // 
        // Thumbnail 생성, 저장 - public/user.id/tn
        //
        resizedImg = await imageCompress({
          image: file.current,
          maxSizeMB: 0.03,    // 30kB 미만
          maxWidthOrHeight: 120
        })
        if (!resizedImg) throw new Error("이미지 처리에 문제가 발생하였습니다.");

        // 
        // 저장만 하므로 fileRes를 덮어쓰지 말아야 한다.
        // 
        const tnRes = await fileUpload(resizedImg, user.id + "/tn");
        if (tnRes.err) throw new Error("Error in fileUpload!");
      }

      if (debug) console.log('fileRes', fileRes);

      let body = {
        ...fields,
        catGroup: getGroup(catList, fields.category),
        image: fileRes.key || fields.image || "",
        mdContent: mdText,
        htmlContent: htmlText
      };

      let res;
      if (isEdit) {
        delete body.category;
        delete body.mdContent;
        res = await postUpdate({ category: fields.category, id: post.id, body })
      } else {
        res = await postCreate({ category: fields.category, body })
      }
      if (res.err) throw Error(res.err.message);
      await onload()
    } catch (e) {
      alert(e.message);
    } finally {
      // window.location.reload()
      setIsLoading(false)
      setIsVisible(false)
    }
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5, marginBottom: 15 }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title={title}>
      <div style={styles.formGroup}>
        <FileInputMd
          label="MD File Upload"
          onChangeInputFile={onChangeInputFileMdFile}
          inputType="file"
        />
      </div>
      <div style={styles.formGroup}>
        <PostFormSection
          {...props}
          isEdit={isEdit}
          editItems={post}
          inputFields={inputFields}
          onSubmit={onSubmit}
          onChangeInputFile={onChangeInputFileImage}
          onSubmitText={isEdit ? "수 정" : "등 록"}
          isLoading={isLoading}
        />
      </div>

    </ModalForm>
  )
}

function updateInputFields(inputFields, cList) {
  let newInputFields = [...inputFields]
  const i = newInputFields.findIndex(f => f.name === "category")
  newInputFields[i].selectList = cList
  return newInputFields
}

function getGroup(catList, cat) {
  const arr = catList.filter(c=>c.category===cat)
  // console.log(arr[0].group)
  return arr[0].group
}