import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage, Table, colors } from "../../components";
import { Plus } from "../../components2/Icons";
import PostModal from "./PostModal";
import { postList, categoryList } from "../../lib/ec2-api-cms-lib";

const header = [
  { name: "catGroup", displayName: "그룹" },
  { name: "category", displayName: "카테고리" },
  { name: "title", displayName: "제목" },
  { name: "id", displayName: "아이디" },
  { name: "publish", displayName: "게시 여부" },
  { name: "excerpt", displayName: "요약" },
  { name: "lastModified", displayName: "수정일자" },
]

let cnt = 0;

export default function Posts(props) {
  const title = "블로그 포스트";
  const [isLoading, setIsLoading] = useState(true)
  const [tableItems, setTableItems] = useState([])
  const [visibleModal, setVisibleModal] = useState(false);
  const [post, setPost] = useState({});
  const [catList, setCatList] = useState([])

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);

      let res = await postList({ category: "all" });
      if (res.err) throw Error(res.err.message)
      console.log(res.result)
      setTableItems(res.result.Items)

      res = await categoryList({group: "all"});
      if (res.err) throw Error(res.err.message)
      setCatList(res.result.Items)
    } catch (e) {
      alert(e.message);
    } finally {
      setIsLoading(false)
    }
  }

  const otherProps = { onload, post, isEdit: false, catList }

  const onSelect = (obj) => {
    setPost(obj);
    props.history.push(`/cms/post/view?category=${obj.category}&id=${obj.id}`)
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <h3 style={{ margin: 5, padding: 0 }}>
          <Plus onClick={()=>setVisibleModal(true)} style={{ color: colors.primary }} />
        </h3>
        <Table
          {...props}
          tableHeader={header}
          rowItems={tableItems}
          onClickItem={onSelect}
        />
        {
          (!isLoading && tableItems.length === 0) &&
          <>
            <h5 style={{ textAlign: "center" }}>등록된 항목이 없습니다.</h5>
            <h5 style={{ textAlign: "center" }}>상단의 + 를 클릭하여 포스팅을 추가하세요!</h5>
          </>
        }
      </Section>

      {isLoading && <LoaderFullPage />}

      {
        (visibleModal && !isLoading)  &&
        <PostModal
          key={"post" + cnt++}
          {...props}
          {...otherProps}
          visible={visibleModal}
          setIsVisible={setVisibleModal}
        />
      }
    </Page>

  )
}