import axios from "axios";
import { debug, conf } from "../settings";

const axiosCall = async ({ method, path, body }) => {
  try {
    const baseUrl = conf.ec2Debug
      ? "http://localhost:5000"
      : conf.ec2ApiEndpoint;

    // if (debug) console.log('baseUrl', baseUrl);

    let res = await axios({
      method,
      url: baseUrl + path,
      data: body,
      headers: { 'x-taptag-key': conf.apiKey }
    });
    // if (debug) console.log('res - api ', res);
    if (res.status !== 200) throw new Error("Response Error");
    return res.data;
  } catch (e) {
    return { err: { message: e.message } };
  }
}

/**
 * @CMS_Category
 * 
 * @param {String} group: 카테고리 그룹 예) onl-shop, 주로 application에 해당할 듯
 * @param {String} category: 카테고리  예) docs
 */
export const categoryCreate = async ({ group, category, body }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  // body: { description, subCategory }
  return await axiosCall({ method: "POST", path, body });
};
export const categoryGet = async ({ group, category }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  return await axiosCall({ method: "GET", path });
};
export const categoryUpdate = async ({ group, category, body }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  // body: { description, subCategory }
  return await axiosCall({ method: "PUT", path, body });
};
export const categoryList = async ({ group }) => {
  let path = `/cms/category/list?group=${group}`;
  return await axiosCall({ method: "GET", path });
};
export const categoryDelete = async ({ group, category }) => {
  let path = `/cms/category?group=${group}&category=${category}`;
  return await axiosCall({ method: "DELETE", path });
};

/**
 * @CMS_Post
 * @param {String} category: 카테고리  예) docs
 */
export const postCreate = async ({ category, id, body }) => {
  let path = `/cms/post?category=${category}`;
  if (id) path += `&id=${id}`;
  return await axiosCall({ method: "POST", path, body });
};
export const postGet = async ({ category, id }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "GET", path });
};
export const postUpdate = async ({ category, id, body }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "PUT", path, body });
};
export const postList = async ({ category }) => {
  let path = `/cms/post/list?category=${category}`;
  return await axiosCall({ method: "GET", path });
};
export const postDelete = async ({ category, id }) => {
  let path = `/cms/post?category=${category}&id=${id}`;
  return await axiosCall({ method: "DELETE", path });
};

/** ***************************************************************************
 * @Misc
 *  **************************************************************************/
export const fileupload = async ({ body }) => {
  const path = `/misc/fileupload`;
  // body: {senderInfo, contactInfo, msg}
  return await axiosCall({ method: "POST", path, body });
};

export function httpFileUpload(file, callback) {
  if (!file) {
    callback({ message: "No Files" })
  }

  const path = "/misc/fileupload"
  const url = conf.ec2Debug 
    ? "http://localhost:5000" + path
    : conf.ec2ApiEndpoint + path;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('prefix', "public/posts/test/")

  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // The file has been uploaded successfully
      console.log("File successfully uploaded!", xhr);
      callback(null, JSON.parse(xhr.response))
    } else {
      callback({ message: "Server Error!" })
    }
  };
  xhr.open('POST', url, true);

  /**
   * @formData를_보내면_자동으로_생성해줌
   * @이부분이_추가되면_boundaryError_발생함
   */
  // xhr.setRequestHeader("Content-Type", "multipart/form-data");

  xhr.setRequestHeader('x-taptag-key', conf.apiKey);
  xhr.send(formData);
}