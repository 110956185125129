import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Log, obj2Html } from "../../../components/LogMessage";
import { useInputFields } from "../../../lib/hooksLib";
import { FormInput } from "../../../components";

export default function ApiTest(props) {
  const {
    title,
    apiName,
    api, apiLoop,
    fields,
    createData, setImageUrl,
    description
  } = props;

  const keys = Object.keys(fields);

  const [reqLog, setReqLog] = useState("");
  const [resLog, setResLog] = useState("");
  const [fields_, setFields_] = useInputFields(fields);

  useEffect(() => {
    setReqLog(obj2Html(fields_));
  }, [fields_]);

  const _createData = () => {
    return createData(fields_, apiName);
  };

  const runApi = async (e) => {
    try {
      const data = _createData();
      let res = await api(data);
      console.log('Response:\n', res);
      if (res.result?.imgUrl) setImageUrl(res.result?.imgUrl);
      setResLog(obj2Html(res));
    } catch (e) {
      console.log('Error         :', e.message);
      console.log('Error.Response:\n', e.response);
    }
  };

  const validate = () => {
    let validated = true;
    // keys.map((key) => {
    //   validated = validated && fields_[key].length > 1;
    // });
    return validated;
  };

  return (
    <div>
      <h2>{title}</h2>
      {
        description &&
        <h5>{description}</h5>
      }
      {/* <Button onClick={runApi} block disabled={!validate()}>Run API</Button> */}
      <Button onClick={apiLoop ? apiLoop : runApi} block disabled={!validate()}>Run API</Button>
      <br />

      {keys.map((key, index) => {
        const myProps = {
          key: index,
          name: key,
          validated: validate(),
          label: key,
          onChange: setFields_,
          value: fields_[key] || "",
        };
        return <FormInput {...myProps} />;
      })}

      <Log title="Request Data" msg={reqLog} />
      <br />
      <Log title="Response Data" msg={resLog} />
    </div>
  );
}
