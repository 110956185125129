import React from "react";
import { Button, Modal } from "react-bootstrap";
import Postcode from "@actbase/react-daum-postcode";

function createMarkup(html) {
  return { __html: html };
}

/**
 *
 * @param {*} props.show
 * @param {*} props.title
 * @param {*} props.children
 * @param {*} props.onClose
 * @param {*} props.onTexte
 * @return {React.Component} Modal
 */
export function ModalView(props) {
  const { show, children, title, onClose, closeText, ...rest } = props;  
  return (
    <Modal {...rest} show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {title && <Modal.Title>{props.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body style={{ overflowWrap: "break-word" }}>{children}</Modal.Body>
      {closeText && (
        <Modal.Footer>
          <Button size="sm" variant="outline-secondary" onClick={onClose}>
            {closeText || "X"}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

/**
 *
 * @param {*} props.show
 * @param {*} props.title
 * @param {*} props.body
 * @param {*} props.onClose
 * example usage
 * <Alert show={alertShow} body={alertBody} onClose={()=>this.setState({alertShow: false})}/>
 */
export const Alert = (props) => {
  const { show, body, title, onClose } = props;
  return (
    <Modal show={show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title || "Alert"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflowWrap: "break-word" }}>
        <div dangerouslySetInnerHTML={createMarkup(body)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

/**
 *
 * @param {B} props.showModal visible flag, show로 변경
 * @param {F} props.unsetShowPostCode
 * @param {S} props.title
 * @param {F} props.onRead
 * @returns
 */
export const PostCodeView = (props) => {
  const { showModal, unsetShowPostCode, title, onRead } = props;
  return (
    <>
      <Modal
        show={showModal}
        onHide={unsetShowPostCode}
        style={{ width: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title || "주소 찾기"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Postcode
            width="300"
            height="300"
            jsOptions={{ animation: true, hideMapBtn: true }}
            onSelected={onRead}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
