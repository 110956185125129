
const comment = {
  kr: {
    fetchError: "정보를 가져오는데 실패하였습니다.!!"
  }
}

const errorMsgKr = {
  fetchError: "정보를 가져오는데 실패하였습니다.!!"
}

export const kr = {
  home: {
    title: "",
    comment: comment.kr,
    errMsg: errorMsgKr,
  },
  shopSection: {
    title: "가맹점별 이용현황 보기",
    errMsg: errorMsgKr,
  },
  memberApproval: {
    title: "회원 관리",
    comment: comment.kr,
    errMsg: errorMsgKr,
  },

  baProduct: {
    title: "정품인증 - 제품 등록 | 관리",
    errMsg: errorMsgKr,
  },
  baAds: {
    title: "정품인증 - 광고 등록 | 관리",
    errMsg: errorMsgKr,
  },
  errMsg: errorMsgKr
}

export const en = {}

export default kr;
