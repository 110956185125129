import React from "react";
import { Page, Section } from "../../components";
import { Tabs, Tab } from "react-bootstrap";
import {
  userCreate,
  userGet,
  userUpdate,
  userList,
  userDelete,
  isExistUsername
} from "../../lib/apiLib";
import TestApi from "./apiUi/TestApi-ui";

export default function UserApiTest(props) {
  const apiId = "User";
  const title = apiId + " API 연동 테스트";

  const apis = [
    {
      apiName: "DoesUserExist",
      api: isExistUsername,
      fields: { username: "admin-4673" },
    },
    {
      apiName: "Create",
      api: userCreate,
      fields: {
        username: "kebinlee",
        userType: "admin",
        email: "kebin@psdl.co.kr"
      },
    },
    {
      apiName: "Get",
      api: userGet,
      fields: { username: "kebinlee" },
    },
    {
      apiName: "Update",
      api: userUpdate,
      fields: { username: "kebinlee", sex: "MAIL" },
    },
    {
      apiName: "Delete",
      api: userDelete,
      fields: { username: "kebinlee" },
    },
    {
      apiName: "List",
      api: userList,
      fields: { userType: "admin" },
    },    
  ];

  const createData = (fields, apiName) => {
    const data = { username: fields.username };
    const body = {};

    if (apiName === "Create") {
      body.username = fields.username;
      body.userType = fields.userType;
      body.email = fields.email;
      data.body = body;
    }

    if (apiName === "Update") {
      body.sex = fields.sex;
      data.body = body;
    }

    if (apiName === "List") {
      data.userType = fields.userType;
    }

    console.log(apiName, ' Parameter:', data);

    return data;
  };

  return (
    <Page title={title} stretch={true}>
      <Tabs
        defaultActiveKey={apis[0].apiName}
        id="uncontrolled-tab-shop"
        className="mb-3"
      >
        {apis.map((item, index) => {
          return (
            <Tab
              eventKey={item.apiName}
              title={apiId + " " + item.apiName}
              key={index}
            >
              <Section padding="10px" bgColor="#eee">
                <TestApi
                  title={apiId + " - " + item.apiName}
                  apiName={item.apiName}
                  api={item.api}
                  fields={item.fields}
                  createData={createData}
                  description={item.description}
                />
              </Section>
            </Tab>
          );
        })}
      </Tabs>
    </Page>
  );
}
