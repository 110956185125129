import React, { useEffect, useState } from "react";
import { Page, Search, Section, Table, LoaderFullPage } from "../../components";
import { debug, strings } from "../../settings";
import { baAdsList } from "../../lib/ec2-api-ba-lib";

const str = strings.baAds;

const header = [
  { name: "adGroup", displayName: "광고 그룹명" },
  { name: "adName", displayName: "광고명" },
  { name: "linkUrl", displayName: "연결 사이트" },
  { name: "lastModified", displayName: "수정일자" },
]

let cnt = 0;
export default function BaAds(props) {
  const title = str.title;
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableItems, setTableItems] = useState([]);

  useEffect(() => {
    onload();
  }, [])

  const onload = async () => {
    try {
      setIsLoading(true);
      let res = await baAdsList({ adGroup: "all" });
      if (res.err) throw new Error(str.errMsg.fetchError + "\n" + res.err.message);
      console.log(res);

      setTableItems(res.result.Items);
    } catch (e) {
      alert(e.message);
    } finally { setIsLoading(false) }
  }

  const onClickSearch = async () => {
    try {
      setIsLoading(true);
    } catch (e) {
      if (debug) console.log(e);
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const onSelect = (obj) => {
    if (debug) console.log(obj);
    props.history.push(`/ba/ads/edit?adGroup=${obj.adGroup}&adName=${obj.adName}`);
  }

  return (
    <Page stretch title={title}>

      <Section bgColor="#fff">
        <Search
          onChange={e => setSearchText(e.target.value)}
          placeholder="광고 그룹명..."
          btnDisabled
          onClick={onClickSearch}
          onKeyUp={() => console.log('Keyup')}
          value={searchText}
        />

        <Table
          key={cnt++}
          {...props}
          tableHeader={header}
          rowItems={tableItems}
          rowItemKey="adGroup"
          onClickItem={onSelect}
        />
      </Section>
      {isLoading && <LoaderFullPage />}
    </Page>
  );
}
