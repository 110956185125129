import React, { useState, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "./Buttons";
import storeImage from "../assets/icons/store_image.svg";
import { useStyles, colors } from "./ui";
import { debug } from "../settings";
import { EyeOpen, EyeClose, CheckedCircle, UnCheckedCircle } from "./Icons";
// import { getTimestampFromNow } from "../lib/utils";
// import Calendar from "react-calendar";
import '../pages/Calendar.css';
// import { ModalForm } from "./Modals";
// import { Table } from "../components";

const commonStyles = {
  wrap: { marginBottom: "15px" },
  // label: { margin: "5px 20px 5px 5px", fontWeight: "500" }
  label: { margin: "0px 20px 0px 0px", fontWeight: "500" },
  borderRadius: 5
}

const height = {
  sm: 44, lg: 50
}

/**
 *
 * @param {S} props.name
 * @param {S} props.label
 * @param {S} props.type
 * @param {*} props.value
 * @param {F} props.onChange
 * @param {S} props.placeholder
 * @param {B} props.validated
 * @param {B} props.readOnly
 * @param {S} props.size sm as default
 * @param {S} props.comment
 * @param {O} props.styles
 *
 * @param {S} props.inputType
 * @param {A} props.selectList 
 *
 * @returns
 */
export function FormInput(props) {
  if (props.inputType === "checkbox") return <CheckBox {...props} />;
  else if (props.inputType === "file") return <FileInput {...props} />;
  else return <Input {...props} />;
}

export function CheckBox({ onChange, checked, name, label, style }) {
  const onClick = () => {
    onChange({ target: { type: "checkbox", name } });
  }

  const styles = {
    wrap: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: "space-between",
      justifyContent: style?.justifyContent || "flex-start",
      alignItems: "center",
      marginBottom: commonStyles.wrap.marginBottom
    },
    label: commonStyles.label
  }

  return (
    // <div style={{...commonStyles.wrap, ...style}}>
    <div style={{ ...styles.wrap, ...style }}>
      {label && <p style={styles.label}>{label}</p>}
      {
        checked
          ? <CheckedCircle style={{ fontSize: "18px", color: colors.primary }} onClick={onClick} />
          : <UnCheckedCircle style={{ fontSize: "18px", color: colors.primary }} onClick={onClick} />
        // ? <On className={className} onClick={onClick} width={18} />
        // : <Off className={className} onClick={onClick} width={18} />
      }
    </div>
    // </div>
  );
}

/**
 * @info_inputTypes text | select 
 * 
 * @param {*} param0 
 * @returns 
 */
export function Input({
  value,
  placeholder,
  inputType,
  onChange,
  className,
  type = "text",
  style, display, width, size = "sm",
  label, name, readOnly,
  validated,
  selectList = [],
  onKeyUp
}) {
  // const dimension = useWindowDimensions();
  const { isMobileDevice } = useSelector((state) => state.platform);
  const { gStyle, colors } = useStyles();

  // let inputHeight = isMobileDevice || size === 'sm' ? height.sm : height.lg;
  let inputHeight = height.lg;
  if (isMobileDevice || size === 'sm') inputHeight = height.sm;
  if (inputType === "textarea") inputHeight = "80px";

  const styles = {
    fontSize: gStyle.text.fontSize,
    display: display,
    width: width || "100%",
    height: inputHeight,
    border: "1px solid",
    // borderColor: validated ? colors.secondary : colors.warning,
    borderColor: colors.secondary,
    paddingLeft: 10, paddingRight: 10,
    color: colors.dark,
    borderRadius: commonStyles.borderRadius,
    backgroundColor: readOnly ? colors.readOnly : "white"
  };

  if (inputType === "select") styles.borderColor = colors.secondary;

  const myProps = {
    style: styles, label,
    name, type, className, value, placeholder, onChange, readOnly
  }

  if (inputType === "select") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <select  {...myProps} >
          {selectList.map((item, index) => <option key={item + index} style={{ padding: 10 }}>{item}</option>)}
        </select>
      </div>
    )
  }

  if (inputType === "textarea") {
    return (
      <div style={commonStyles.wrap}>
        {label && <p style={commonStyles.label}>{label}</p>}
        <textarea {...myProps} />
      </div>
    )
  }

  if (inputType === "password") {
    return <InputPassword {...myProps} />
  }

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input {...myProps} onKeyUp = {onKeyUp} />
    </div>
  );
}

function InputPassword(props) {
  const { label } = props;
  const [seen, setSeen] = useState(false);

  return (
    <div style={commonStyles.wrap}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input
        {...props}
        type={seen ? "text" : "password"}
      />
      {
        seen
          ? <EyeOpen style={{ marginLeft: "-30px" }} onClick={() => setSeen(!seen)} />
          : <EyeClose style={{ marginLeft: "-30px" }} onClick={() => setSeen(!seen)} />
      }

    </div>
  )
}

export function FileInput(props) {
  const {
    label,
    value,
    size = "sm",
    onChangeInputFile
  } = props;

  const imgInput = useRef();
  // const [imageUrl, setImageUrl] = useState({});
  const [imagePreview, setImagePreview] = useState(value);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const onSelectImage = (event) => {
    if (debug) console.log('event.target.files', event.target.files);
    if (event.target.files && event.target.files.length) {
      setSelectedImageFile(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0]));  // 이미지 미리보기 
      onChangeInputFile(event);
    }
  }

  const onClickImage = () => {
    // console.log('click');
    imgInput.current.click();
  }

  const styles = {
    txt: { fontSize: "16px", color: "#2b2b2b", },
    imgPreview: { marginTop: 3, marginBottom: 3, width: '20%', borderRadius: 8 }
  }

  return (
    <div style={{ ...commonStyles.wrap, ...styles.txt }}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input style={{ display: 'none' }} type='file' accept='image/*' ref={imgInput} onChange={onSelectImage} />
      <Button title='파일 선택' onClick={onClickImage} variant="outline-secondary" width="100%" size={size}>
        <img src={storeImage} width={20} height={20} alt="fileupload icon" />&nbsp;&nbsp;
      </Button>
      {imagePreview &&
        <div>
          <img src={imagePreview} style={styles.imgPreview} alt="preview" />
          <p style={{ fontSize: 12, textAlign: 'left', margin: 0 }}>
            첨부 파일: {selectedImageFile ? selectedImageFile.name : ""}
          </p>
        </div>
      }
    </div>
  )
}

export function FileInputMd(props) {
  const {
    label,
    size = "sm",
    onChangeInputFile
  } = props;

  const inputFile = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  const onSelected = (event) => {
    if (debug) console.log('event.target.files', event.target.files);
    if (event.target.files && event.target.files.length) {
      setSelectedFile(event.target.files[0]);
      onChangeInputFile(event);
    }
  }

  const onClickImage = () => {
    // console.log('click');
    inputFile.current.click();
  }

  const styles = { txt: { fontSize: "16px", color: "#2b2b2b", } }

  return (
    <div style={{ ...commonStyles.wrap, ...styles.txt }}>
      {label && <p style={commonStyles.label}>{label}</p>}
      <input style={{ display: 'none' }} type='file' ref={inputFile} onChange={onSelected} />
      <Button title='파일 선택' onClick={onClickImage} variant="outline-secondary" width="100%" size={size}>
        <img src={storeImage} width={20} height={20} alt="fileupload icon" />&nbsp;&nbsp;
      </Button>
      {selectedFile &&
        <p style={{ fontSize: 12, textAlign: 'left', margin: 0 }}>
          첨부 파일: {selectedFile ? selectedFile.name : ""}
        </p>
      }
    </div>
  )
}

/**
 *
 * @param {S} props.onClick
 * @param {*} props.btnText
 * @param {*} props.size
 * @param {*} props.validated
 * @param {*} props.disabled
 * @returns_bootstrap_FormControl
 */
export function InputWithBtn(props) {
  const { onClick, btnText, size, validated, disabled } = props;
  // if (debug) console.log("disabled?", !validated || disabled === true);
  const styles = {
    wrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
    inputWrap: { width: "60%" },
    btnWrap: { width: "35%", ...commonStyles.wrap }
  };
  return (
    <div style={styles.wrap}>
      <div style={styles.inputWrap}>
        <FormInput {...props} size={size} style={{ marginBottom: 0 }} />
      </div>
      <div style={styles.btnWrap}>
        <Button
          width="100%"
          variant="info"
          size={size}
          disabled={!validated || disabled === true}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
}

export const Search = (props) => {
  const {
    onChange,
    onClick,
    value,
    placeholder,
    btnDisabled,
    btnText,
    onKeyUp,
  } = props;
  return (
    <Form.Group as={Row} controlId="search-text">
      <Col sm="3">
        <Form.Control
          size="sm"
          type="text"
          onChange={onChange}
          placeholder={placeholder || "search text here..."}
          onKeyPress={onKeyUp}
          value={value || ""}
        />
      </Col>
      <Col sm="9">
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={onClick}
          disabled={btnDisabled === "true"}
        >
          {btnText || "Search"}
        </Button>
      </Col>
    </Form.Group>
  );
};

export const Search2 = (props) => {
  const { onClick, btnDisabled, btnText, ...rest } = props;
  return (
    <div style={{ display: "flex", justifyContent: "flex-start"}}>
      <FormInput {...rest} />
      <Button
        style={{marginLeft: 15}}
        size="sm"
        variant="outline-secondary"
        onClick={onClick}
        disabled={btnDisabled === "true"}
      >
        {btnText || "Search"}
      </Button>
    </div>
  );
};

export const SelectFilters = (props) => {
  const { defaultValue, list, onChange, onClick } = props;
  return (
    <Form.Group as={Row} controlId="categoryInput">
      <Col sm="3">
        <Form.Control
          onChange={onChange}
          size="sm"
          type="text"
          as="select"
          value={defaultValue}
        >
          {list.map((item, index) => {
            return <option key={index}>{item.name}</option>;
          })}
        </Form.Control>
      </Col>
      {/* <Form.Label column sm="1">Filter </Form.Label> */}
      <Button
        size="sm"
        variant="outline-secondary"
        onClick={onClick}
      >
        Filter
      </Button>
    </Form.Group>
  );
};
