import {
  userCreate, userGet, userList, userUpdate, userDelete, isExistUsername, changePassword, cognitoListUsers,
  sendEmail, sendSms,

  shopCreate, shopGet, shopList, shopUpdate, shopDelete, checkShopExist,

  shopItemCreate, shopItemGet, shopItemList, shopItemDelete, shopItemUpdate,        // unionpos, dummypos, smilepos
  okposItemCreate, okposItemGet, okposItemList, okposItemUpdate , okposItemDelete,  // okpos only

  shopItemOptionCreate, shopItemOptionGet, shopItemOptionUpdate, shopItemOptionList, shopItemOptionDelete,
  okposItemOptionCreate, okposItemOptionGet, okposItemOptionUpdate, okposItemOptionList, okposItemOptionDelete,
  okposProxy,

  ntag424Enc, ntag424Dec, ntag424Cmac, ntag424Sesskeys,
  ntag424VerifyPiccData, ntag424VerifyToken,
  nfctagVersionCreate, nfctagVersionGet, nfctagVersionUpdate, nfctagVersionDelete, nfctagVersionList,
  unionposProxy2, unionposCopyImage, smileposProxy,
  unionposProxy,

  dummyposAddOrder, dummyposGetOrderList, dummyposUpdateOrderByOrderId,
  dummyposGetOrderListByTable, dummyposDelete,
} from "../../lib/ec2-api-lib";
import {
  categoryCreate, categoryGet, categoryUpdate, categoryList, categoryDelete,
  postCreate, postGet, postUpdate, postList, postDelete,
} from "../../lib/ec2-api-cms-lib"

import {
  baAdsCreate, baAdsGet, baAdsList, baAdsUpdate, baAdsDelete,
  baProductCreate, baProductGet, baProductList, baProductUpdate, baProductDelete,
  baTagCreate, baTagGet, baTagUpdate, baTagDelete, baTagAuth
} from "../../lib/ec2-api-ba-lib";


const users = [
  {
    apiName: "UserCreate",
    api: userCreate,
    fields: {
      username: "test-user",
      body: { userType: "user" }
    },
  },
  {
    apiName: "UserGet",
    api: userGet,
    fields: { username: "test-user", },
  },
  {
    apiName: "UserUpdate",
    api: userUpdate,
    fields: {
      username: "test-user",
      body: { userType: "admin" }
    },
  },
  {
    apiName: "UserListByUserType",
    api: userList,
    fields: { userType: "admin" },
  },
  {
    apiName: "UserDelete",
    api: userDelete,
    fields: { username: "test-user", },
  },
  {
    apiName: "UserListByUserType",
    api: userList,
    fields: { userType: "admin" },
  },

  // congnito
  {
    apiName: "isExistUsername",
    api: isExistUsername,
    fields: { username: "shop-4673-3" },
  },
  {
    apiName: "cognitoUserListByPhoneNo",
    api: cognitoListUsers,
    fields: { phone_number: "01053174673" },
  },
  {
    apiName: "ChangePassword",
    api: changePassword,
    fields: { username: "shop-4673-3", password: "psdl12@$" },
  },
]

// email | sms
const miscs = [
  {
    apiName: "sendSms",
    api: sendSms,
    fields: { phone_number: "01053174673" },
  },
  // {
  //   apiName: "sendEmail",
  //   api: sendEmail,
  //   fields: {
  //     body: {
  //       senderInfo: "이기봉입니다.",
  //       contactInfo: "kebin@example.com",
  //       msg: "연락 부탁합니다!"
  //     }
  //   },
  // },
];

const testShop = {
  // psdltest, username "티알디",
  unionPos: {
    shopId: "150f76e017dcc60a51d9",
    storeCode: "3000000033377",
    licenseNo: "1028146446",
  },
  smilePos: {
    shopId: "12345",
    storeCode: "3100488001",
  },
  dummyPos: {
    shopId: "43a047780a6478c1c9c4",
    storeCode: "P5288701357-1682396649",
    tableCode: "3"
  }
}

const shops = [
  // {
  //   apiName: "shopCreate",
  //   api: shopCreate,
  //   fields: { shopId: "1234", body: { posName: "SmilePOS", } },
  // },
  // {
  //   apiName: "shopGet",
  //   api: shopGet,
  //   fields: { shopId: "1234" },
  // },
  // {
  //   apiName: "shopUpdate",
  //   api: shopUpdate,
  //   fields: { shopId: "1234", body: { shopName: "PSDL - smilePos" } },
  // },
  // {
  //   apiName: "shopDelete",
  //   api: shopDelete,
  //   fields: { shopId: "1234" },
  // },
  {
    apiName: "checkShopExist",
    api: checkShopExist,
    fields: { posname: "okpos", storeCode: "TESTSHOP" },
  },
];

const shopItems = [
  {
    apiName: "shopItemCreate",
    api: shopItemCreate,
    fields: { shopId: "1234", id: "5678" },
  },
  {
    apiName: "shopItemGet",
    api: shopItemGet,
    fields: { shopId: "1234", id: "5678" },
  },
  {
    apiName: "shopItemUpdate",
    api: shopItemUpdate,
    fields: { shopId: "1234", id: "5678", body: { itemCode: "00001" } },
  },
  {
    apiName: "shopItemList",
    api: shopItemList,
    fields: { shopId: "1234", token: "11" },
  },
  {
    apiName: "shopItemDelete",
    api: shopItemDelete,
    fields: { shopId: "1234", id: "5678" },
  },
];

const shopItemOptions = [
  {
    apiName: "shopItemOptionCreate",
    api: shopItemOptionCreate,
    // fields: { shopId: "1234", body: { optionName: "psdl", msg: "Kebin No 1" } },
    fields: { shopId: "1234", optionName: "psdl", body: { msg: "Kebin No 1" } },
  },
  {
    apiName: "shopItemOptionGet",
    api: shopItemOptionGet,
    fields: { shopId: "1234", optionName: "psdl" },
  },
  {
    apiName: "shopItemOptionUpdate",
    api: shopItemOptionUpdate,
    fields: { shopId: "1234", optionName: "psdl", body: { msg: "hello world" } },
  },
  {
    apiName: "shopItemOptionList",
    api: shopItemOptionList,
    fields: { shopId: "1234" },
  },
  {
    apiName: "shopItemOptionDelete",
    api: shopItemOptionDelete,
    fields: { shopId: "1234", optionName: "psdl" },
  },
];

const dummyposApis = [
  {
    apiName: "dummyPos - addOrder",
    api: dummyposAddOrder,
    fields: {
      shopId: testShop.dummyPos.shopId,
      body: {
        shopId: testShop.dummyPos.shopId,
        TableCode: testShop.dummyPos.tableCode,
        OrderData: [{ ItemCode: "00017", Qty: "1", Price: "12000", IsPack: "C" }],
        OrderQty: "1",
        StoreCode: testShop.dummyPos.storeCode
      },
    },
  },
  // {
  //   apiName: "dummyPos - addOrder",
  //   api: dummyposAddOrder,
  //   fields: {
  //     shopId: testShop.dummyPos.shopId,
  //     body: {
  //       shopId: testShop.dummyPos.shopId,
  //       TableCode: testShop.dummyPos.tableCode,
  //       OrderData: [{ ItemCode: "00015", Qty: "1", Price: "12000", IsPack: "C" }],
  //       OrderQty: "1",
  //       StoreCode: testShop.dummyPos.storeCode
  //     },
  //   },
  // },
  // {
  //   apiName: "dummyPos - addOrder",
  //   api: dummyposAddOrder,
  //   fields: {
  //     shopId: testShop.dummyPos.shopId,
  //     body: {
  //       shopId: testShop.dummyPos.shopId,
  //       TableCode: testShop.dummyPos.tableCode,
  //       OrderData: [{ ItemCode: "00017", Qty: "1", Price: "12000", IsPack: "C" }],
  //       OrderQty: "1",
  //       StoreCode: testShop.dummyPos.storeCode
  //     },
  //   },
  // },
  // {
  //   apiName: "dummyPos - addOrder",
  //   api: dummyposAddOrder,
  //   fields: {
  //     shopId: testShop.dummyPos.shopId,
  //     body: {
  //       shopId: testShop.dummyPos.shopId,
  //       TableCode: testShop.dummyPos.tableCode,
  //       OrderData: [{ ItemCode: "00051", Qty: "2", Price: "3000", IsPack: "C" }],
  //       OrderQty: "1",
  //       StoreCode: testShop.dummyPos.storeCode
  //     },
  //   },
  // },

  // {
  //   apiName: "dummyPos - updateOrder",
  //   api: dummyposUpdateOrderByOrderId,
  //   fields: { shopId: testShop.dummyPos.shopId, orderId: "9e896ff973fd276f6443" },
  // },

  // {
  //   apiName: "dummyPos - get",
  //   api: dummyposGetOrderList,
  //   fields: { shopId: testShop.dummyPos.shopId, lastModified: 0 },
  // },

  // {
  //   apiName: "dummyPos - getByTableNo",
  //   api: dummyposGetOrderListByTable,
  //   fields: { shopId: testShop.dummyPos.shopId, tableNo: testShop.dummyPos.tableCode },
  // },

  // {
  //   apiName: "dummyPos - delete Test",
  //   api: dummyposDelete,
  //   fields: { shopId: testShop.dummyPos.shopId },
  // },
]

// http://172.30.1.27:3000/v1/150f76e017dcc60a51d9/007/auth?e=62952B8772D7BBAB80F5E77C28CFD926&m=1122334455667788
const unionposApis = [
  // {
  //   apiName: "unionProxy - shopInfo",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "shopInfo",
  //     body: { BizNo: testShop.unionPos.licenseNo }
  //   },
  // },

  // {
  //   apiName: "unionProxy - groupList",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "groupList",
  //     body: { StoreCode: testShop.unionPos.storeCode }
  //   },
  // },

  {
    apiName: "unionpos-order",
    // api: unionposProxy2,
    api: unionposProxy,
    fields: {
      apiName: "order",
      body: {
        shopId: testShop.unionPos.shopId,
        StoreCode: testShop.unionPos.storeCode,
        TableCode: "15",
        OrderData: [
          {
            ItemCode: "00205",
            Qty: "1",
            Price: "32000",
            IsPack: "C"
          }
        ],
        OrderQty: "1",
      }
    },
  },

  // {
  //   apiName: "unionProxy - ServiceTime",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "serviceTimeList",
  //     body: { StoreCode: testShop.unionPos.storeCode, }
  //   },
  // },

  // {
  //   apiName: "unionProxy - LangList",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "langList",
  //     body: { StoreCode: testShop.unionPos.storeCode, }
  //   },
  // },

  // {
  //   apiName: "unionProxy - TableList",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "tableList",
  //     body: { StoreCode: testShop.unionPos.storeCode, }
  //   },
  // },

  // {
  //   apiName: "unionProxy - TableCall",
  //   api: unionposProxy2,
  //   fields: {
  //     apiName: "tableCall",
  //     body: { 
  //       StoreCode: testShop.unionPos.storeCode,
  //       TableCode: "17",
  //        "CallType" : "Call",
  //       }
  //   },
  // },

  // {
  //   apiName: "unionposCopyImage",
  //   api: unionposCopyImage,
  //   fields: {
  //     body: {
  //       shopId: "kebin",  // public/kebin/파알명
  //       imageUrl: "https://psdl-oneul-storage.s3.ap-northeast-2.amazonaws.com/public/3af23262d155ff09b77f/콜라.png"
  //     }
  //   },
  // }
];

const ntag424 = [
  {
    apiName: "Enc",
    api: ntag424Enc,
    fields: {
      body: {
        key: { data: "1309C877509E5A215007FF0ED19CA564" },
        iv: { data: "00".repeat(16) },
        msg: "A55A9D00C4DF01000000000000000000",
        // expected: "3E27082AB2ACC1EF55C57547934E9962"
      }
    },
  },
  {
    apiName: "Dec",
    api: ntag424Dec,
    fields: {
      body: {
        key: { data: "1309C877509E5A215007FF0ED19CA564" },
        iv: { data: "00".repeat(16) },
        msg: "3E27082AB2ACC1EF55C57547934E9962",
        // expected: "A55A9D00C4DF01000000000000000000",
      }
    },
  },
  {
    apiName: "Cmac",
    api: ntag424Cmac,
    fields: {
      body: {
        key: { data: "4C6626F5E72EA694202139295C7A7FC7" },
        msg: "5F01009D00C4DF0261B6D97903566E84C3AE5274467E89EA",
        // expected: "7BD75F991CB7A2C18DA09EEF047A8D04",
      }
    },
  },
  {
    apiName: "Sesskeys",
    api: ntag424Sesskeys,
    fields: {
      body: {
        key: { data: "00".repeat(16) },
        rndA: "13C5DB8A5930439FC3DEF9A4C675360F",
        rndB: "B9E2FC789B64BF237CCCAA20EC7E6E48",
        // expected: {
        //   sessAuthEnc: "1309C877509E5A215007FF0ED19CA564",
        //   sessAuthMac: "4C6626F5E72EA694202139295C7A7FC7",
        // }
      },
    },
  },
  {
    apiName: "VerifyPiccData",
    api: ntag424VerifyPiccData,
    fields: {
      body: {
        ver: "v1",
        tableNo: "007",
        piccdata: "62952B8772D7BBAB80F5E77C28CFD926",
      }
    },
  },
  {
    apiName: "VerifyPiccData-debug",
    api: ntag424VerifyPiccData,
    fields: {
      body: {
        ver: "v1",
        tableNo: "007",
        piccdata: "62952B8772D7BBAB80F5E77C28CFD926",
        mode: "debug",
      }
    },
  },
  {
    apiName: "ONL Auth - VerifyToken",
    api: ntag424VerifyToken,
    fields: {
      body: {
        tableNo: "007",
        token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0YWJsZU5vIjoiMDA3IiwiaWF0IjoxNjc1MjQxNDU3LCJleHAiOjE2NzUyNDE2OTd9.3eTAYezAyJ0pRevm-30kXqgBmqO-rJTSFwVazTkMLHE"
      }
    },
  },
];

const nfctagVersions = [
  {
    apiName: "nfctagVersionCreate",
    api: nfctagVersionCreate,
    // fields: { docVersion: "2022-08-15", tagVersion: "v1-1", body: { baseUrl: "https://psdl.co.kr" } },
    fields: { docVersion: "2022-08-15", body: { tagVersion: "v1-1", baseUrl: "https://psdl.co.kr" } },
  },
  {
    apiName: "nfctagVersionGet",
    api: nfctagVersionGet,
    fields: { docVersion: "2022-08-15", tagVersion: "v1-1" },
  },
  {
    apiName: "nfctagVersionUpdate",
    api: nfctagVersionUpdate,
    fields: { docVersion: "2022-08-15", tagVersion: "v1-1", body: { baseUrl: "https://order.psdl.co.kr/aaaaaaaaaa" } },
  },
  {
    apiName: "nfctagVersionList",
    api: nfctagVersionList,
    fields: { docVersion: "2022-08-15", },
  },
  {
    apiName: "nfctagVersionDelete",
    api: nfctagVersionDelete,
    fields: { docVersion: "2022-08-15", tagVersion: "v1-1", },
  },
];

const baAds = [
  {
    apiName: "BA adsCreate",
    api: baAdsCreate,
    fields: {
      adGroup: "PSDL", adName: "ONL Tag",
      body: {
        accessUrl: "www.taptag.co.kr"
      }
    },
  },
  {
    apiName: "BA adsGet",
    api: baAdsGet,
    fields: { adGroup: "PSDL", adName: "ONL Tag", },
  },
  {
    apiName: "BA adsList",
    api: baAdsList,
    fields: { adGroup: "PSDL" },
  },
  {
    apiName: "BA adsUpdate",
    api: baAdsUpdate,
    fields: {
      adGroup: "PSDL", adName: "ONL Tag",
      body: { accessUrl: "www.iamtoday.co.kr" }
    }
  },
  {
    apiName: "BA adsDelete",
    api: baAdsDelete,
    fields: { adGroup: "PSDL", adName: "ONL Tag", },
  },
];
const baProducts = [
  {
    apiName: "BA productCreate",
    api: baProductCreate,
    fields: {
      company: "Test Company", productName: "Test Product",
      body: {
        accessUrl: "www.taptag.co.kr"
      }
    },
  },
  {
    apiName: "BA productGet",
    api: baProductGet,
    fields: {
      company: "Test Company", productName: "Test Product",
    },
  },
  {
    apiName: "BA productList",
    api: baProductList,
    fields: {
      company: "Test Company",
    },
  },
  {
    apiName: "BA productUpdate",
    api: baProductUpdate,
    fields: {
      company: "Test Company", productName: "Test Product",
      body: { accessUrl: "www.iamtoday.co.kr" }
    }
  },
  {
    apiName: "BA productDelete",
    api: baProductDelete,
    fields: {
      company: "Test Company", productName: "Test Product",
    },
  },
];
const baTags = [
  {
    apiName: "BA tagCreate",
    api: baTagCreate,
    fields: {
      ver: "v1", uid: "112233",
      body: {
        ver: "v1", uid: "112233",
        company: "PSDL"
      }
    },
  },
  {
    apiName: "BA tagGet",
    api: baTagGet,
    fields: {
      ver: "v1", uid: "112233",
    },
  },
  {
    apiName: "BA tagUpdate",
    api: baTagUpdate,
    fields: {
      ver: "v1", uid: "112233",
      body: { company: "PSDL 자회사" }
    },
  },
  {
    apiName: "BA tagDelete",
    api: baTagDelete,
    fields: {
      ver: "v1", uid: "112233",
    },
  },
  {
    apiName: "BA tagAuth",
    api: baTagAuth,
    fields: {
      ver: "v1", uid: "112233", cnt: "000022", hotp: "0011223344556677"
    },
  },
];

const smileposApis = [
  {
    apiName: "SmilePOS - 메뉴변경확인",
    api: smileposProxy,
    fields: {
      apiName: "isMenuUpdated",
      body: { "STORE_ID": testShop.smilePos.storeCode, "SERVICE_ID": "3001", "UPDATE_CHECK_DTIME": "20150625133326" }
    },
  },
  {
    apiName: "SmilePOS - 메뉴다운로드",
    api: smileposProxy,
    fields: {
      apiName: "updateMenu",
      body: { "STORE_ID": testShop.smilePos.storeCode }
    },
  },
  {
    apiName: "SmilePOS - 테이블리스트",
    api: smileposProxy,
    fields: {
      apiName: "tableList",
      body: { "STORE_ID": testShop.smilePos.storeCode, "SERVICE_ID": "" }
    },
  },
]

const cmsCategory = [
  {
    apiName: "CMS categoryCreate",
    api: categoryCreate,
    fields: {
      group: "onl-shop", category: "user-guides",
      body: {
        description: "shop app user guide docs"
      }
    },
  },
  {
    apiName: "CMS categoryGet",
    api: categoryGet,
    fields: { group: "onl-shop", category: "user-guides", },
  },
  {
    apiName: "CMS categoryList",
    api: categoryList,
    fields: { group: "onl-shop" },
  },
  {
    apiName: "CMS categoryUpdate",
    api: categoryUpdate,
    fields: {
      group: "onl-shop", category: "user-guides",
      body: {
        subCategory: "등록 방법"
      }
    },
  },
  {
    apiName: "CMS categoryDelete",
    api: categoryDelete,
    fields: { group: "onl-shop", category: "user-guides", },
  },
]

const cmsPost = [
  {
    apiName: "CMS post Create",
    api: postCreate,
    fields: {
      category: "user-guides", id: "",
      body: {
        post: "shop app user guide docs"
      }
    },
  },
  {
    apiName: "CMS post Get",
    api: postGet,
    fields: { category: "user-guides", id: "1234", },
  },
  {
    apiName: "CMS post List",
    api: postList,
    fields: { category: "user-guides" },
  },
  {
    apiName: "CMS post Update",
    api: postUpdate,
    fields: {
      category: "user-guides", id: "1234",
      body: {
        post: "등록 방법"
      }
    },
  },
  {
    apiName: "CMS post Delete",
    api: postDelete,
    fields: { category: "user-guides", id: "1234", },
  },
]

const okposItems = [
  {
    apiName: "okposItemCreate",
    api: okposItemCreate,
    fields: { shopId: "1234", id: "5678", body: { itemCode: "00001" }},
  },
  {
    apiName: "okposItemGet",
    api: okposItemGet,
    fields: { shopId: "1234", id: "5678" },
  },
  {
    apiName: "okposItemUpdate",
    api: okposItemUpdate,
    fields: { shopId: "1234", id: "5678", body: { itemCode: "00002" } },
  },
  {
    apiName: "okposItemList",
    api: okposItemList,
    fields: { shopId: "1234", token: "11" },
  },
  {
    apiName: "okposItemDelete",
    api: okposItemDelete,
    fields: { shopId: "1234", id: "5678" },
  },
]

const okposItemOptions = [
  {
    apiName: "okposItemOptionCreate",
    api: okposItemOptionCreate,
    fields: { shopId: "1234", optionName: "psdl", body: { msg: "Kebin No 1" } },
  },
  {
    apiName: "okposItemOptionGet",
    api: okposItemOptionGet,
    fields: { shopId: "1234", optionName: "psdl" },
  },
  {
    apiName: "okposItemOptionUpdate",
    api: okposItemOptionUpdate,
    fields: { shopId: "1234", optionName: "psdl", body: { msg: "hello world" } },
  },
  {
    apiName: "okposItemOptionList",
    api: okposItemOptionList,
    fields: { shopId: "1234" },
  },
  {
    apiName: "okposItemOptionDelete",
    api: okposItemOptionDelete,
    fields: { shopId: "1234", optionName: "psdl" },
  },
];

const okposApis = [
  // {
  //   apiName: "okpos - storeInfo",
  //   api: okposProxy,
  //   fields: {
  //     apiName: "storeInfo",
  //     body: { SHOP_CD: "1001" }
  //   },
  // },
  // {
  //   apiName: "okpos - setPolicy",
  //   api: okposProxy,
  //   fields: {
  //     apiName: "setPolicy",
  //     body: { SHOP_CD: "1001" }
  //   },
  // },
  // {
  //   apiName: "okpos - order",
  //   api: okposProxy,
  //   fields: {
  //     apiName: "order",
  //     body: { SHOP_CD: "1001" }
  //   },
  // },
  // {
  //   apiName: "okpos - orderInfo",
  //   api: okposProxy,
  //   fields: {
  //     apiName: "orderInfo",
  //     body: { SHOP_CD: "1001" }
  //   },
  // },
  // {
  //   apiName: "okpos - testApi",
  //   api: okposProxy,
  //   fields: {
  //     apiName: "testApi",
  //     body: { SHOP_CD: "1001" }
  //   },
  // },
]

export const apisEc2 = [
  // ...miscs,

  // ...users,
  ...shops,

  // ...shopItems,
  // ...okposItems,

  // ...shopItemOptions,
  // ...okposItemOptions,

  // ...dummyposApis,

  // ...unionposApis,  // unionProxy2 사용
  // ...smileposApis,
  // ...okposApis,

  // ...ntag424,
  // ...nfctagVersions,

  // ...baAds,
  // ...baProducts,
  // ...baTags,

  // ...cmsCategory,
  // ...cmsPost,
];
