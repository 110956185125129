export const stage = "prod";
export const appName = `"오늘" Admin`;

export const ec2ApiEndpoint = "https://api.onl.taptag.co.kr";
export const authEndpoint = "https://auth.onl.taptag.co.kr/";
export const apiKey = "F43653B64C291AFDE25B7365A64970DE";

export const authDebug = false;
// export const authDebug = true;

export const ec2Debug = false;
// export const ec2Debug = true;

export const dqrSettingDebug = false;   // auth.onl.taptag.co.kr

export const debug = false;
// export const debug = true;
