
export const baProduct = {
  inputFields: [
    {
      name: 'company',
      editable: false,
      // type: 'String'
    },
    {
      name: 'productName',
      editable: false,
      type: 'String',
      comment: "제품의 모델명"
    },
    {
      name: 'productGroup',
      type: 'String',
      comment: "제품의 그룹명",
      required: false
    },
    {
      name: 'ver',
      type: 'String',
      defaultValue: "v1",
    },
    {
      name: 'adGroup',
      type: 'String',
      comment: "광고의 그룹네임을 입력."
    },
    {
      name: 'accessUri',
      type: 'String',
      // editable: false,
      defaultValue:
        'https://ba-demo.taptag.co.kr/ba/v1/11223344556677?cnt=112233&hash=1122334455667788990011223344556677889900&adGroup=adgroup',
    },
    {
      name: 'optionalInfo',
      editable: false,
      comment: "아래 Optional Information 에서 작성함."
    },
    {
      name: 'image',
      type: 'file',
      inputType: "file",
      required: false,
    },
  ]
}

/**
 * 가변 필드이지만 기본 필드를 제공함.
 */
export const baProductOptions = {
  inputFields: [
    {
      name: 'origin',
      comment: "제품의 원산지"
    },
    {
      name: 'manufacturedDate',
      comment: "형식: 2021 May 25"
    },
    {
      name: 'price',
      comment: "형식: US$500.00 | KRW500,000"
    },
    {
      name: 'description',
      comment: "제품의 기타 정보"
    },
  ]
}

export const baTag = {
  configName: "configTagTable",
  inputFields: [
    {
      name: 'uid',
      type: 'String',
      comment: "Tag의 UID, ascii 값의 string 임."
    },
    {
      name: 'company',
      type: 'String',
      defaultValue: 'England King',
    },
    {
      name: 'productName',
      type: 'String',
      defaultValue: 'Royal Salute 30'
    },
    {
      name: 'ver',
      type: 'String',
      defaultValue: 'v1'
    },
  ]
}

export const baAds = {
  inputFields: [
    {
      name: 'adGroup',
      displayName: "광고그룹",
      defaultValue: 'liquer',
      comment: "인증 페이지에 보여줄 광고 리스트 그룹명. 제품 카테고리 혹은 회사명을 사용하는 것이 편리함.",
      editable: false
    },
    {
      name: 'adName',
      displayName: "광고명",
      defaultValue: 'jinro',
      editable: false
    },
    {
      name: 'linkUrl',
      displayName: "연결 사이트",
      defaultValue: 'www.psdl.co.kr',
      comment: "광고 클릭 시 보여줄 웹 페이지 링크"
    },
    {
      name: 'image',
      displayName: "이미지",
      inputType: "file",
      comment: "인증 페이지에 보여줄 광고 이미지.",
      required: false,
    },
  ]
}
