import React, { useState } from "react";
import { useStyles, Button, FormInput, ModalForm } from "../../components2";
import { useInputFields } from "../../lib/hooksLib";
import { debug } from "../../settings";
import { categoryCreate, categoryUpdate } from "../../lib/ec2-api-cms-lib";


export default function CategoryModal(props) {
  const { setIsVisible, visible, onload, cat, isEdit } = props;
  const title = "블로그 그룹 | 카테고리 추가";

  const { gStyle } = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  let defaultFields = {
    group: "",
    category: "",
    description: "",
  };

  if (isEdit) {
    defaultFields.group = cat.group
    defaultFields.category = cat.category
    defaultFields.description = cat.description
  }

  const [fields, setFields] = useInputFields(defaultFields)

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const params = {
        group: fields.group,
        category: fields.category,
        body: { description: fields.description },
      }
      let res;
      if (isEdit) {
        res = await categoryUpdate(params)
      } else {
        res = await categoryCreate(params)
      }
      if (res.err) throw Error(res.err.message);
      setIsLoading(false);
      await onload();
      setIsVisible(false);
    } catch (e) {
      alert(e.message);
      setIsVisible(false);
    }
  }

  const onSubmitDelete = async () => {
    alert("카테고리 삭제는 나중에...")
    setIsVisible(false);
  }

  const validate = () => {
    return true
  }

  const styles = {
    text: { ...gStyle.text, marginBottom: "3px" },
    formGroup: { margin: 0, padding: 5 }
  };

  return (
    <ModalForm visible={visible} setIsVisible={setIsVisible} title={title}>
      <div style={styles.formGroup}>
        <FormInput
          label="블로그 그룹"
          name="group"
          validated={validate()}
          value={fields.group}
          onChange={setFields}
        />
        <FormInput
          label="블로그 카테고리"
          name="category"
          validated={validate()}
          value={fields.category}
          onChange={setFields}
        />
        <FormInput
          label="카테고리 설명"
          name="description"
          validated={validate()}
          value={fields.description}
          onChange={setFields}
        />
      </div>

      <Button
        size="sm"
        variant="primary"
        width="100%"
        title={isEdit ? "수정" : "추가"}
        onClick={onSubmit}
        isLoading={isLoading}
      />
      <br />

      {
        isEdit &&
        <Button
          size="sm"
          variant="dark"
          width="100%"
          title="삭제"
          onClick={onSubmitDelete}
        />
      }
    </ModalForm>
  )
}
