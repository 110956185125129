import React, { useEffect } from "react";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
// import { Button } from "react-bootstrap";
// import { useCookies } from "react-cookie";
// import { useInputFields } from "../../lib/hooksLib";
// import { FormInput } from "../FormInputs";
import { gStyle } from "../ui";

// https://www.showwcase.com/show/19231/how-to-build-a-dynamic-qr-code-using-react

export default function QRCode() {

  const urls = [
    "https://order.taptag.co.kr/v2/01234567890123456789/001?e=62952B8772D7BBAB80F5E77C28CFD926",
    "https://order.taptag.co.kr/v2/01234567890123456789/002?e=62952B8772D7BBAB80F5E77C28CFD926"
  ]

  const shopId = "8e03d67337a878473a44"
  const data = [
    {tableNo: "001"},
    {tableNo: "002"},
    {tableNo: "003"},
  ]

  useEffect(() => {
    
  }, [])

  const styles = {
    qrWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      // alignItems: "center",
      flexWrap: "wrap",
      margin: "15x"
    },
    urlText: { margin: 0, overflow: "wrap" }
  }

  return (
    <div>
      <h2 style={{ ...gStyle.title, ...gStyle.bold }}>
        QR Code
      </h2>
      <div style={styles.qrWrap}>
        {urls.map(url => {
          return (
            <div>
              <QRCodeSVG value={url} />
              <p styles={styles.urlText}>{url}</p>
            </div>
          )
        })}
      </div>

    </div>
  )
}