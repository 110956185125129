import { posList } from "./posList";
import { debug } from "../settings";

const pos = {
  "UNIONPOS": { validateMenus: validateMenusUnionpos, },
  "OKPOS": { validateMenus: validateMenusOkpos },
}

export function validateMenus({ shopInfo, posMenu, onlMenu }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].validateMenus({ shopInfo, posMenu, onlMenu });
}

/** @UnionPos */

function validateMenusUnionpos({ shopInfo, posMenu, onlMenu }) {

  const checkListPos = []
  const checkListOnl = []
  let validated = true;

  if (shopInfo.posName === "UNIONPOS") {

    // 
    // Pos 메뉴
    // 
    checkListPos.push({
      checkItem: "Pos Menu ItemList Count",
      value: posMenu.ItemList.length
    })
    checkListPos.push({
      checkItem: "Pos Menu SetMenuList Count",
      value: posMenu.SetMenuList.length
    })
    checkListPos.push({
      checkItem: "Pos Menu ChoiceMenuList Count",
      value: posMenu.ChoiceMenuList.length
    })

    // 
    // ONL 메뉴
    // 
    checkListOnl.push({
      checkItem: "ONL Menu ItemList Count",
      value: onlMenu.itemList.length
    })
    checkListOnl.push({
      checkItem: "ONL Menu SetMenuList Count",
      value: onlMenu.itemOptions.SetMenuList.length
    })
    checkListOnl.push({
      checkItem: "ONL Menu ChoiceMenuList Count",
      value: onlMenu.itemOptions.ChoiceMenuList.length
    })
  }

  const { val, notFoundPosItemList, undeletedOnlItemList } = validateMenuItemsUnionpos(posMenu, onlMenu)

  validated = validated
    && posMenu.SetMenuList.length === onlMenu.itemOptions.SetMenuList.length
    && posMenu.ChoiceMenuList.length === onlMenu.itemOptions.ChoiceMenuList.length
    && val

  return {
    checkListPos, checkListOnl, validated,
    notFoundPosItemList, undeletedOnlItemList
  }
}

function validateMenuItemsUnionpos(posMenu, onlMenu) {
  let val = true;
  const notFoundPosItemList = []
  const undeletedOnlItemList = []

  posMenu.ItemList.map(posItem => {
    const onlItems = onlMenu.itemList.filter(onlItem => onlItem.itemCode === posItem.ItemCode)
    val = val && onlItems.length === 1
    if (onlItems.length !== 1) {
      notFoundPosItemList.push(posItem)
    }
    return null
  })

  onlMenu.itemList.map(onlItem => {
    const posItems = posMenu.ItemList.filter(posItem => posItem.ItemCode === onlItem.itemCode)
    val = val && posItems.length === 1
    if (posItems.length !== 1) undeletedOnlItemList.push(onlItem)
    return null
  })

  val = val && (posMenu.ItemList.length === onlMenu.itemList.length)
  return { val, notFoundPosItemList, undeletedOnlItemList }
}

/** @OKPos */

function validateMenusOkpos({ posMenu, onlMenu }) {
  const checkListPos = []
  const checkListOnl = []
  let validated = true;

  if (debug) {
    console.log('posMenu', posMenu)
    console.log('onlMenu', onlMenu)
  }

  const ItemList = posMenu.Item.PRODS || []
  const TuClsList = posMenu.Item.TUCLSCODE || []
  const SdsCdList = posMenu.Item.SDSCDCODE || []
  const SdaCdList = posMenu.Item.SDACDCODE || []

  // 
  // Pos 메뉴
  // 
  checkListPos.push({
    checkItem: "Pos Menu ItemList Count",
    value: ItemList.length
  })
  checkListPos.push({
    checkItem: "Pos Menu 터치분류리스트 TuClsList Count",
    value: TuClsList.length
  })
  checkListPos.push({
    checkItem: "Pos Menu 속성메뉴리스트 SdaCdList Count",
    value: SdaCdList.length
  })
  checkListPos.push({
    checkItem: "Pos Menu 선택메뉴리스트 SdsCdList Count",
    value: SdsCdList.length
  })

  // 
  // ONL 메뉴
  // 
  checkListOnl.push({
    checkItem: "ONL Menu ItemList Count",
    value: onlMenu.itemList.length
  })
  checkListOnl.push({
    checkItem: "ONL Menu 터치분류리스트 TuClsList Count",
    value: onlMenu.itemOptions?.TuClsList.length
  })
  checkListOnl.push({
    checkItem: "ONL Menu 속성메뉴리스트 SdaCdList Count",
    value: onlMenu.itemOptions?.SdaCdList.length
  })
  checkListOnl.push({
    checkItem: "ONL Menu 선택메뉴리스트 SdsCdList Count",
    value: onlMenu.itemOptions?.SdsCdList.length
  })

  const {
    val,
    notFoundPosItemList,
    undeletedOnlItemList,
    invalidOnlTuClassList
  } = validateMenuItemsOkpos(posMenu, onlMenu)

  validated = validated
    && SdaCdList.length === onlMenu.itemOptions.SdaCdList.length
    && SdsCdList.length === onlMenu.itemOptions.SdsCdList.length
    && val

  return {
    checkListPos, checkListOnl, validated,
    notFoundPosItemList, undeletedOnlItemList,
    invalidOnlTuClassList
  }
}

function validateMenuItemsOkpos(posMenu, onlMenu) {
  let val = true;
  const notFoundPosItemList = []
  const undeletedOnlItemList = []
  const invalidOnlTuClassList = []

  const posItemList = posMenu.Item.PRODS || []
  const posTuClsList = posMenu.Item.TuClsList || []
  const onlTuClsList = onlMenu.itemOptions?.TuClsList || []

  posItemList.map(posItem => {
    const onlItems = onlMenu.itemList.filter(onlItem => onlItem.itemCode === posItem.PROD_CD)
    val = val && onlItems.length === 1
    if (onlItems.length !== 1) {
      notFoundPosItemList.push(posItem)
    }
    return null
  })

  onlMenu.itemList.map(onlItem => {
    const posItems = posItemList.filter(posItem => posItem.PROD_CD === onlItem.itemCode)
    val = val && posItems.length === 1
    if (posItems.length !== 1) undeletedOnlItemList.push(onlItem)

    return null
  })

  posTuClsList.map(posCls => {
    const onlClses = onlTuClsList.filter(onlCls => onlCls["TU_CLS_CD"] === posCls["TU_CLS_CD"])

    val = val && onlClses.length === 1

    if (onlClses.length === 1) {
      const posTuClsItems = posCls.itemList
      const onlTuClsItems = onlClses[0].itemList
      posTuClsItems.sort()
      onlTuClsItems.sort()

      if (debug) console.log(posCls["TU_CLS_CD"], JSON.stringify(posTuClsItems), JSON.stringify(onlTuClsItems))

      const isSame = JSON.stringify(posTuClsItems) === JSON.stringify(onlTuClsItems)
      val = val && isSame
      if (!isSame) {
        invalidOnlTuClassList.push({
          TU_CLS_CD: onlClses[0]["TU_CLS_CD"],
          TU_CLS_NM: onlClses[0]["TU_CLS_NM"],
          OnlTuClassItems: JSON.stringify(onlTuClsItems),
          PosTuClassItems: JSON.stringify(posTuClsItems)
        })
      }
    } else {
      invalidOnlTuClassList.push({
        TU_CLS_CD: posCls["TU_CLS_CD"],
        TU_CLS_NM: posCls["TU_CLS_NM"],
        OnlTuClassItems: "NOT SAVED",
        PosTuClassItems: "",
      })
    }
  })

  val = val && notFoundPosItemList.length === 0
  val = val && undeletedOnlItemList.length === 0
  val = val && invalidOnlTuClassList.length === 0

  return {
    val,
    notFoundPosItemList,
    undeletedOnlItemList,
    invalidOnlTuClassList
  }
}