import { unionposProxy2, okposProxy } from "../lib/ec2-api-lib";
import { sortObjArray, delay } from "../lib/utils";
import { debug } from "../settings";

const pos = {
  "UNIONPOS": { getPosMenu: getPosMenuUnionpos, },
  "OKPOS": { getPosMenu: getPosMenuOkpos, },
  // "OKPOS": { getPosMenu: () => { return { err: { message: "NOT SUPPORTED" } } } },
}
const posList = Object.keys(pos);

export function getPosMenu({ shopInfo, ...rest }) {
  if (!posList.includes(shopInfo.posName)) return { err: { message: "INVALID POSNAME" } };
  return pos[shopInfo.posName].getPosMenu({ shopInfo, ...rest });
}

async function getPosMenuUnionpos({ shopInfo }) {
  try {
    let res = await unionposProxy2({
      apiName: "itemList",
      body: { StoreCode: shopInfo.storeCode }
    })
    if (res.err) throw Error(res.err.message)
    if (res.result?.CODE !== "S00000") throw Error(res.result.MSG)
    return { result: res.result.DATA }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

function getPosMenuOkpos({ shopInfo, setComment }) {
  const storeCode = shopInfo.storeCode
  return getMasterInfo({storeCode, setComment})
  // try {
  //   const storeCode = shopInfo.storeCode
  //   const res = await getMasterInfo({storeCode, setComment})
  //   if(res.err) throw Error(res.err.message)
    
  //   return res
  // } catch (e) {
  //   return { err: { message: e.message } }
  // }
}

/** ****************************************************************
 * @OKPOS_Logic storeApp menuUpdate 코드 
 * ****************************************************************/

const delayTime = 50;

async function getMasterInfo({ storeCode, setComment }) {
  try {

    const targets = [
      "PRODS",
      "SDACLCODE", "SDACDCODE",
      "SDSGRCODE", "SDSCLCODE", "SDSCDCODE",
      // "CLSLMCODE", "CLSMMCODE", "CLSSMCODE",
      "TUCLSCODE",
      "TUKEYCODE",
      "TABLE"
    ]

    let errMsg = "";

    const masterInfo = {}

    for (let i = 0; i < targets.length; i++) {
      let res = await getTargetMaster({
        body: {
          'SHOP_CD': storeCode,
          'COUNT_PER_PAGE': '30',
          'CURRENT_PAGE_NUMBER': '1',
          'TARGET': targets[i]
        },
        setComment
      });
      if (res.err) {
        errMsg = `Fetching MasterInfo FAILED - ${res.err.message} at ${targets[i]}`
        break;
      }
      masterInfo[targets[i]] = res.result[targets[i]]
    }
    if (errMsg !== "") throw Error(errMsg)

    const validProdList = getValidProdList({ masterInfo })

    let mergedProds = mergeProdsTukey({ prodList: validProdList, tukeyList: masterInfo["TUKEYCODE"] })
    mergedProds = sortObjArray({ arr: mergedProds, key: "PROD_CD" })
    masterInfo["PRODS"] = mergedProds

    // 
    // added to compare with itemOptions.TuClsList
    // 
    masterInfo["TuClsList"] = createTuClsList({masterInfo})

    return { result: { Item: masterInfo } }
  } catch (e) {
    if (debug) console.log(e)
    return { err: { message: e.message } }
  }
}

/**
 * 
 * @param {*} param.body {SHOP_CD, COUNT_PER_PAGE, CURRENT_PAGE_NUMBER, TARGET}
 * @returns 
 */
async function getTargetMaster({ body, targetData = [], setComment, totalPages = "1", retryCnt = 0 }) {
  try {
    // 
    // delay for loop masterInfo
    // 
    await delay(delayTime)

    const targetName = body["TARGET"]

    let comment = `Receiving ${targetName} page ${body['CURRENT_PAGE_NUMBER']}`
    if (parseInt(totalPages) > 1) {
      comment += ` of total ${totalPages} pages`
    }
    if (retryCnt > 0) comment += `Retry ${retryCnt}`
    setComment(comment)

    let res = await okposProxy({ apiName: "targetMasterInfo", body });
    if (res.err) throw Error(res.err.message);

    const result = {}
    const d = res.result
    if (d.RESULT_CODE !== "0000") {

      // 
      // 요청한 페이지에 데이터가 없음  생성된 페이지 수를 넘긴 값을 요청할 경우
      // 
      if (d.RESULT_CODE === "8300") { 
        result[targetName] = []
        return { result }
      }

      // 
      // retry
      // 
      if (d.RESULT_CODE === "9003") {

        if (retryCnt > 3) throw Error(getErrorMsg(res));
        return await getTargetMaster({
          body,
          targetData,
          setComment,
          totalPages,
          retryCnt: retryCnt + 1
        })
      }
      throw Error(getErrorMsg(res)); // POS res 에러 처리
    }
    const newTargetData = [...targetData, ...d.DATA[targetName]]

    if (d.CURRENT_PAGE_NUMBER !== d.TOTAL_PAGE_COUNT) {
      // await delay(delayTime)
      const newBody = {
        ...body,
        'CURRENT_PAGE_NUMBER': (parseInt(body.CURRENT_PAGE_NUMBER) + 1).toString(),
      }

      return await getTargetMaster({
        body: newBody,
        targetData: newTargetData,
        setComment,
        totalPages: d.TOTAL_PAGE_COUNT
      })
    } else {
      retryCnt = 0
      result[targetName] = newTargetData
      return { result }
    }
  } catch (e) {
    return { err: { message: e.message } }
  }
}

/**
 * prod이 SDA 혹은 SDS에 따라 또 다른 item을 사용할 경우 추가하여 리턴
 * 
 */
function getProdsInUse({ tukey, prod, menu }) {
  let prodsInUse = [prod]

  if (prod["SIDE_MENU_YN"] === "Y") {

    // 
    // 선택
    // 
    if (prod["SDS_GRP_CD"] !== "") {
      const sdsClsList = menu["SDSCLCODE"]
      const sdsCdList = menu["SDSCDCODE"]

      let sdsClsListForItem = sdsClsList.filter(sdsCls => sdsCls.SDS_GRP_CD === prod.SDS_GRP_CD)

      sdsClsListForItem.map(cls => {
        const sdsCdListForItem = sdsCdList.filter(cd => cd.SDS_CLS_CD === cls.SDS_CLS_CD)
        const prods = sdsCdListForItem.map(sdsCd => {
          const _prods = menu.PRODS.filter(prod => prod.PROD_CD === sdsCd.PROD_CD)
          if (_prods.length === 1) return _prods[0]
          return null
        })
        prodsInUse = [...prodsInUse, ...prods]
      })
    }
  }
  return prodsInUse
}

function getValidProdList({ masterInfo }) {
  let validProdList = []

  masterInfo["TUKEYCODE"].map((tukey, i) => {

    // 
    // TUKEY로부터 prod 획득
    //
    const prods = masterInfo["PRODS"].filter(prod => tukey.PROD_CD === prod.PROD_CD)
    if (prods.length !== 1) return null

    // 
    // SDS list 로부터 prodInUse list 획득
    // 
    const prodsInUse = getProdsInUse({ tukey, prod: prods[0], menu: masterInfo })
    validProdList = [...validProdList, ...prodsInUse]
    return null
  })

  if (debug) {
    console.log('validProdList len', validProdList.length)
  }

  // 
  // validProdList 에 이미 있으면 제거
  // 
  const validProdList2 = getUniqueArray({ arr: validProdList, key: "PROD_CD" })
  if (debug) console.log('validProdList2 len', validProdList2.length)
  return validProdList2
}

function mergeProdsTukey({ prodList, tukeyList }) {
  if (prodList.length === 0 || tukeyList.length === 0) return []
  const newPosItems = prodList.map(prod => {
    const tuListForItem = tukeyList.filter(tu => tu.PROD_CD === prod.PROD_CD)
    if (tuListForItem.length > 0) {
      prod.tuClassList = tuListForItem.map(tu => tu.TU_CLS_CD)
    } else {
      prod.tuClassList = []
    }
    return prod
  })
  return newPosItems
}

function getUniqueArray({ arr, key }) {
  const newArr = []
  arr.map(item => {
    const items = newArr.filter(newItem => newItem[key] === item[key])
    if (items.length === 0) newArr.push(item)
    return null
  })
  return newArr
}

function getErrorMsg(res) {
  let errMsg = res.result.RESULT_CODE;
  errMsg += " - " + res.result.RESULT_MSG;
  errMsg += " at " + res.result.COMMAND;
  return errMsg
}

function createTuClsList ({masterInfo}){

  const tuClsList = masterInfo["TUCLSCODE"]
  const tuKeyList = masterInfo["TUKEYCODE"]

  const newTuClsList = tuClsList.map(cls=>{
    const tuKeys = tuKeyList.filter(tu=>tu["TU_CLS_CD"]===cls["TU_CLS_CD"])
    const prods = tuKeys.map(tu=>tu["PROD_CD"])
    return{...cls, itemList: prods}
  })

  return newTuClsList
}