import React, { useState, useEffect } from "react";
import { Page, Section } from "../../../components";
import { useStyles, LoaderFullPage, CustomTable, Button } from "../../../components2";
import { shopGet } from "../../../lib/ec2-api-lib";
import { getItemOption, parseItemOptions } from "../../../lib-pos";
import { dqrHwCheckCon, getDqrList } from "../../../lib/ec2-dqr-api";
import DqrResyncModal from "./DqrResyncModal";
import DqrProvisionModal from "./DqrProvisionModal";
import { Plus, CircleFilled } from "../../../components2/Icons";
import qs from "qs";
import { sortObjArray } from "../../../lib/utils";
// import { debug } from "../../../settings";

const tableHeader = [
  // { name: "created", displayName: "Created" },
  { name: "uid", displayName: "UID" },
  { name: "tableNo", displayName: "Table No" },
  { name: "tableName", displayName: "Table Name" },
  { name: "ver", displayName: "Version" },
  { name: "active", displayName: "In Use" },
];

export default function DqrSetting(props) {
  const title = "DQR Setting"
  const q = qs.parse(window.location.search.split('?')[1]);



  const [isLoading, setIsLoading] = useState(false);
  const [shopInfo, setShopInfo] = useState({})
  const [itemOptions3, setItemOptions3] = useState({})

  const [dqrList, setDqrList] = useState([])
  const [selDqr, setSelDqr] = useState({})
  const [visible, setVisible] = useState(false)
  const [visibleProvision, setVisibleProvision] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [dqrHwCon, setDqrHwCon] = useState(false)
  const [dqrHwInfo, setDqrHwInfo] = useState({})

  useEffect(() => {
    async function onload() {
      try {
        setIsLoading(true)
        let res = await shopGet({ shopId: q.shopId })
        if (res.err) throw Error(res.err.message)

        const sInfo = res.result.Item

        res = await getItemOption({ shopInfo: sInfo })
        if (res.err) throw Error(res.err.message)

        const optionsParsed = parseItemOptions({ shopInfo: sInfo, itemOptions: res.result.Item })
        if (res.err) throw Error(res.err.message)

        setShopInfo(sInfo)
        setItemOptions3(optionsParsed)
      } catch (e) {
        alert(e.message)
      } finally { setIsLoading(false) }
    }
    onload()
  }, [])

  useEffect(() => {
    listDqrs()
  }, [shopInfo])

  /** @TODO delete dqr -> active=false */
  const listDqrs = async () => {
    try {
      const res = await getDqrList({ shopId: shopInfo.shopId })
      if (res.err) throw Error(res.err.message)
      const newList = sortObjArray({ arr: res.result.Items, key: "created", type: "down" })
      setDqrList(newList)
    } catch (e) {
      alert(e.message)
    }
  }

  useEffect(() => {
    checkDqrConnection()
  }, [])

  const checkDqrConnection = async () => {
    try {

      // 
      // response
      // 1. NOCONN Error
      // 2. Empty Object {}
      // 3. Provision Data {ver, shopId, ...}
      // 
      const res = await dqrHwCheckCon()
      if (res.err) throw Error(res.err.message)
      console.table(res)

      setDqrHwCon(true)
      setDqrHwInfo(res)
    } catch (e) {
      let errMsg = e.message
      if (e.message === "Network Error") errMsg = "Bridge App NOT Connected"
      alert(errMsg)
      setDqrHwCon(false)
      setDqrHwInfo({})
      setVisibleProvision(false)
    }
  }

  const onClickItem = async (obj) => {
    setSelDqr(obj)
    setVisible(true)
  }

  return (
    <Page stretch title={title}>
      <Section bgColor="#fff">
        <h2>가맹점 - {shopInfo.shopName}</h2>

        <Button variant="outline-primary" title="Connect DQR" onClick={checkDqrConnection} size="sm" />
        <hr />

        <Header
          setIsEdit={setIsEdit}
          setVisibleProvision={setVisibleProvision}
          dqrHwCon={dqrHwCon}
          dqrHwInfo={dqrHwInfo}
        />

        <CustomTable
          tableHeader={tableHeader}
          rowItems={dqrList}
          onClickItem={onClickItem}
        />
      </Section>

      <DqrResyncModal
        visible={visible}
        setVisible={setVisible}
        dqr={selDqr}
      />

      {visibleProvision &&
        <DqrProvisionModal
          visible={visibleProvision}
          setVisible={setVisibleProvision}
          checkConnection={checkDqrConnection}
          isEdit={isEdit}
          dqr={dqrHwInfo}
          shopInfo={shopInfo}
          itemOptions3={itemOptions3}
          updateDqrList={listDqrs}
        />
      }

      {isLoading && <LoaderFullPage />}
    </Page>
  );
}

function Header(props) {
  const { setIsEdit, setVisibleProvision, dqrHwCon, dqrHwInfo } = props
  const { gStyle, colors } = useStyles();

  const styles = {
    subTitleBar: {
      display: "flex",
      alignItems: "center"
    },
    subTitle: { ...gStyle.subtitle },
    iconPlus: { ...gStyle.subtitle, color: colors.primary, marginLeft: 10 },
  }

  return (
    <div style={{ ...styles.subTitleBar, justifyContent: "space-between", marginBottom: 5 }}>
      <div style={styles.subTitleBar}>
        <p style={styles.subTitle}>Provisioned DQR List</p>
        {dqrHwCon &&
          <Plus
            style={styles.iconPlus}
            onClick={() => {
              setIsEdit(false)
              setVisibleProvision(true)
            }}
          />
        }
      </div>

      {dqrHwCon &&
        <div style={styles.subTitleBar}>
          <p style={styles.subTitle} >
            <span
              style={{ color: colors.danger, cursor: "pointer", fontSize: "1rem" }}
              onClick={() => {
                setIsEdit(true)
                setVisibleProvision(true)
              }}
            >{dqrHwInfo.uid}</span>
            &nbsp;&nbsp;
            <ConSign />
          </p>
        </div>
      }
    </div>
  )
}

function ConSign() {
  const { colors } = useStyles();
  const [passTime, setPassTime] = useState(false)

  useEffect(() => {
    const iv = setInterval(() => {
      setPassTime(pre => !pre)
    }, 500)

    return () => clearInterval(iv)
  }, [])

  return <CircleFilled style={{ color: passTime ? colors.danger : colors.warning }} />
}