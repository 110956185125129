import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage, } from "../../components";
import PostFormSection from "../../components/PostFormSection";
import { debug } from "../../settings";
import { baAds } from "../../settings/ba-db";
import { baAdsGet, baAdsUpdate, baAdsCreate } from "../../lib/ec2-api-ba-lib";
import { fileUpload } from "../../lib/apiLib";
import { imageCompress } from "../../lib/utils";
import qs from "qs";

export default function AdRegister(props) {
  const action = props.match?.params?.action;
  const isEdit = action === "edit";
  const title = "광고 정보 " + (isEdit ? "수정" : "등록");

  const inputFields = baAds.inputFields;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editItems, setEditItems] = useState({});

  /**
   * debug 때 페이지 reload를 편하게 하기위해 별도로 fetch함
   */
  useEffect(() => {
    async function onload() {
      try {
        setIsLoading(true);
        if (isEdit) {
          const q = qs.parse(window.location.search.split('?')[1]);
          let res = await baAdsGet({ adGroup: q.adGroup, adName: q.adName });
          if (res.err) throw Error(res.err.message);
          if (debug) console.log('res', res);
          setEditItems(res.result.Item);
        }
        setIsLoaded(true);
      } catch (e) {
        if (debug) console.log(e);
        alert(e.message);
      } finally {
        setIsLoading(false)
      }
    }
    onload();
  }, [])

  const file = {};
  const onChangeInputFile = (e) => {
    file.current = e.target.files[0];
    if (debug) console.log("file.current", file.current);
  }

  const onSubmit = async (fields) => {
    try {
      setIsLoading(true);

      let fileRes = {};
      if (file.current) {
        let resizedImg = await imageCompress({ image: file.current, maxSizeMB: 0.3 });
        if (!resizedImg) throw new Error("Error in image resizing...");
        // if (debug) console.log('resizedImg', resizedImg);

        fileRes = await fileUpload(resizedImg, "ba-products");
        if (fileRes.err) throw new Error("Error in fileUpload...");
      }
      if (debug) console.log('fileuploaded', fileRes);

      let body = {};

      //
      // body 생성
      // 
      inputFields.map(item => {

        //
        // edit_모드에서는_변경된_속성값만_body로_넘겨줌
        // 
        if (isEdit) {
          if (fields[item.name] !== editItems[item.name]) body[item.name] = fields[item.name];
        } else {
          if (item.name !== "image") body[item.name] = fields[item.name];
        }

        // 
        // image는 별도 fileUpload에서 리턴받은 key (image url)를 넘겨줌
        // "" 로 overwrite 되지 않도록 마지막에 삽입함
        // 
        if (item.name === "image" && fileRes.key !== undefined) body[item.name] = fileRes.key;

        return null;
      });

      if (debug) console.log('body', body);


      if (Object.keys(body).length === 0)
        throw new Error((isEdit ? "수정" : "저장") + "할 데이터가 없습니다.");

      let res;
      let data = {
        adGroup: fields.adGroup,
        adName: fields.adName,
        body
      }
      if (isEdit) res = await baAdsUpdate({ ...data });
      else res = await baAdsCreate({ ...data });
      if (res.err) throw new Error(res.err.message);

      setIsLoading(false);
      props.history.goBack();
    } catch (e) {
      if (debug) console.log(e.message);
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <Page stretch title={title}>
      {isLoaded &&
        <Section>
          <PostFormSection
            {...props}
            isEdit={isEdit}
            editItems={editItems}
            inputFields={inputFields}
            onSubmit={onSubmit}
            onChangeInputFile={onChangeInputFile}
            onCancel={() => { props.history.goBack() }}
            title={`광고 수정 : ${editItems.adName}`}
          />
        </Section>
      }
      {isLoading && <LoaderFullPage />}
    </Page>
  )
}

// function FormSection(props) {
//   const { title = "제품 등록", editItems, isEdit, setIsLoading } = props;
//   const inputFields = baAds.inputFields;

//   const originalFields = createFields({ inputFields, editItems: isEdit ? editItems : null });
//   const [fields, setFields] = useInputFields(
//     { ...originalFields }
//   );
//   const file = {};

//   // useEffect(() => {
//   //   if (debug) console.log('editItems', editItems);
//   //   if (editItems && editItems.optionalInfo) setOptionalInfo(JSON.parse(editItems.optionalInfo));
//   //   else _createOptionInfo();
//   // }, [editItems])

//   const onChangeInputFile = (e) => {
//     file.current = e.target.files[0];
//     if (debug) console.log("file.current", file.current);
//   }

//   const validate = () => {
//     let val = true;
//     inputFields.map((item) => {
//       val = val && validateInputValue(item, fields[item.name]);
//     });
//     return val;
//   };

//   const onSubmit = async () => {
//     try {

//       setIsLoading(true);

//       let fileRes = {};
//       if (file.current) {
//         let resizedImg = await imageCompress({ image: file.current, maxSizeMB: 0.3 });
//         if (!resizedImg) throw new Error("Error in image resizing...");
//         // if (debug) console.log('resizedImg', resizedImg);

//         fileRes = await fileUpload(resizedImg, "ba-products");
//         if (fileRes.err) throw new Error("Error in fileUpload...");
//       }
//       if (debug) console.log('fileuploaded', fileRes);

//       let body = {};

//       //
//       // body 생성
//       //
//       inputFields.map(item => {

//         //
//         // edit_모드에서는_변경된_속성값만_body로_넘겨줌
//         //
//         if (isEdit) {
//           if (fields[item.name] !== editItems[item.name]) body[item.name] = fields[item.name];
//         } else {
//           if (item.name !== "image") body[item.name] = fields[item.name];
//         }

//         //
//         // image는 별도 fileUpload에서 리턴받은 key (image url)를 넘겨줌
//         // "" 로 overwrite 되지 않도록 마지막에 삽입함
//         //
//         if (item.name === "image" && fileRes.key !== undefined) body[item.name] = fileRes.key;

//         return null;
//       });

//       console.log('body', body);


//       if (Object.keys(body).length === 0)
//         throw new Error((isEdit ? "수정" : "저장") + "할 데이터가 없습니다.");

//       let res;
//       let data = {
//         company: fields.company,
//         productName: fields.productName,
//         body
//       }
//       if (isEdit) res = await baAdsUpdate({ ...data });
//       else res = await baAdsCreate({ ...data });
//       if (res.err) throw new Error(res.err.message);

//       setIsLoading(false);
//       props.history.goBack();
//     } catch (e) {
//       if (debug) console.log(e.message);
//       alert(e.message);
//       setIsLoading(false);
//     }
//   }


//   const myStyle = {
//     title: { margin: "20px 0" },
//     wrap: { padding: "20px" },
//     subTitle: { paddingBottom: "10px" },
//     btnWrap: { display: "flex", justifyContent: "space-between", padding: "20px 0" },
//   };

//   return (
//     <Section>
//       <h3 style={myStyle.title}>{title}</h3>
//       {inputFields.map((item, index) => {
//         const myProps = createInputProps({ item, index, fields, setFields })
//         if (isEdit && (item.editable === false)) myProps.readOnly = true;
//         if (item.inputType === "file") myProps.onChangeInputFile = onChangeInputFile;
//         return <FormInput {...props} {...myProps} />;
//       })}


//       <div style={myStyle.btnWrap}>
//         <Button variant="outline-secondary" onClick={() => props.history.goBack()}>취소</Button>
//         <Button
//           variant="danger"
//           onClick={onSubmit}
//           disabled={!validate()}
//         >확인</Button>
//       </div>
//     </Section>

//   )
// }
