import React, { useEffect, useState } from "react";
import { Page, Section, LoaderFullPage, FormInput } from "../../components";
import { createFields, createInputProps, validateInputValue } from "../../components/PostFormSection";
import { useInputFields } from "../../lib/hooksLib";
import { debug } from "../../settings";
import { baProduct, baProductOptions } from "../../settings/ba-db";
import { baProductGet, baProductUpdate, baProductCreate } from "../../lib/ec2-api-ba-lib";
import { fileUpload } from "../../lib/apiLib";
import { Form, Button, Row, Col, } from "react-bootstrap";
import { renameKey, imageCompress } from "../../lib/utils";
import qs from "qs";

export default function ProductRegister(props) {
  const action = props.match?.params?.action;
  const isEdit = action === "edit";
  const title = "제품 정보 " + (isEdit ? "수정" : "등록");

  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [editItems, setEditItems] = useState({});

  /**
   * debug 때 페이지 reload를 편하게 하기위해 별도로 fetch함
   */
  useEffect(() => {
    async function onload() {
      try {
        if (isEdit) {
          const q = qs.parse(window.location.search.split('?')[1]);
          const company = q.company;
          const productName = q.productName;
          let res = await baProductGet({ company, productName });
          if (res.err) throw Error(res.err.message);
          if (debug) console.log('res', res);
          setEditItems(res.result.Item);
        }
        setIsLoaded(true);
      } catch (e) {
        if (debug) console.log(e);
        alert(e.message);
      }
    }
    onload();
  }, [])

  return (
    <Page stretch title={title} >
      {isLoaded &&
        < FormSection
          {...props}
          title={title}
          isEdit={isEdit}
          editItems={editItems}
          setIsLoading={setIsLoading}
        />
      }
      {isLoading && <LoaderFullPage />}
    </Page>
  )
}

function FormSection(props) {
  const { title = "제품 등록", editItems, isEdit, setIsLoading } = props;
  const inputFields = baProduct.inputFields;
  const optionalInputFields = baProductOptions.inputFields;

  const originalFields = createFields({ inputFields, editItems: isEdit ? editItems : null });
  const [fields, setFields] = useInputFields(
    { ...originalFields }
  );
  const [optionalInfo, setOptionalInfo] = useState([]);
  const file = {};

  useEffect(() => {
    if (debug) console.log('editItems', editItems);
    if (editItems && editItems.optionalInfo) setOptionalInfo(JSON.parse(editItems.optionalInfo));
    else _createOptionInfo();
  }, [editItems])

  const onChangeInputFile = (e) => {
    file.current = e.target.files[0];
    if (debug) console.log("file.current", file.current);
  }

  const _createOptionInfo = () => {
    const optInfo = [];
    optionalInputFields.map(item => {

      // 
      // 데이터 형태
      // optionalInfo: [{ 'origin': "" }, { 'manufacturedDate': "" }, { 'price': "" }, { 'description': "" }]
      // 
      let obj = {};
      obj[item.name] = item.defaultValue || "";
      optInfo.push(obj);
      return null;
    });

    setOptionalInfo(optInfo);
  }

  const validate = () => {
    let val = true;
    inputFields.map((item) => {
      val = val && validateInputValue(item, fields[item.name]);
      return null;
    });
    return val;
  };

  const onSubmit = async () => {
    try {

      setIsLoading(true);

      let fileRes = {};
      if (file.current) {
        let resizedImg = await imageCompress({ image: file.current, maxSizeMB: 0.3 });
        if (!resizedImg) throw new Error("Error in image resizing...");
        // if (debug) console.log('resizedImg', resizedImg);

        fileRes = await fileUpload(resizedImg, "ba-products");
        if (fileRes.err) throw new Error("Error in fileUpload...");
      }
      if (debug) console.log('fileuploaded', fileRes);

      let body = {};

      //
      // body 생성
      // 
      inputFields.map(item => {

        //
        // edit_모드에서는_변경된_속성값만_body로_넘겨줌
        // 
        if (isEdit) {
          if (fields[item.name] !== editItems[item.name]) body[item.name] = fields[item.name];
        } else {
          if (item.name !== "image") body[item.name] = fields[item.name];
        }

        if (item.name === "optionalInfo") body[item.name] = JSON.stringify(optionalInfo);

        // 
        // image는 별도 fileUpload에서 리턴받은 key (image url)를 넘겨줌
        // "" 로 overwrite 되지 않도록 마지막에 삽입함
        // 
        if (item.name === "image" && fileRes.key !== undefined) body[item.name] = fileRes.key;

        return null;
      });

      if (debug) console.log('body', body);
      // setIsLoading(false);
      // return      

      if (Object.keys(body).length === 0)
        throw new Error((isEdit ? "수정" : "저장") + "할 데이터가 없습니다.");

      let res;
      let data = {
        company: fields.company,
        productName: fields.productName,
        body
      }
      if (isEdit) res = await baProductUpdate({ ...data });
      else res = await baProductCreate({ ...data });
      if (res.err) throw new Error(res.err.message);

      setIsLoading(false);
      props.history.goBack();
    } catch (e) {
      if (debug) console.log(e.message);
      alert(e.message);
      setIsLoading(false);
    }
  }

  const addInfoItem = (e) => {
    let newOptInfo = [...optionalInfo];
    const newItem = {}
    newItem["item " + newOptInfo.length] = "...";
    newOptInfo.push(newItem)
    setOptionalInfo(newOptInfo);
  }

  const removeInfoItem = (e) => {
    let newOptInfo = [...optionalInfo];
    newOptInfo.pop()
    setOptionalInfo(newOptInfo);
  }

  const onChangeInfoItem = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;

    let newOptInfo = [...optionalInfo];
    let obj = newOptInfo[index];
    let newObj = {};
    if (name === "itemKey") {
      newObj = renameKey(obj, Object.keys(obj)[0], value)
    } else {
      newObj[Object.keys(obj)[0]] = value
    }
    // if (debug) console.log(obj, newObj)
    newOptInfo[index] = newObj;
    setOptionalInfo(newOptInfo);
    setFields({ target: { name: "optionalInfo", value: JSON.stringify(newOptInfo) } });
  }

  const myStyle = {
    title: { margin: "20px 0" },
    wrap: { padding: "20px" },
    subTitle: { paddingBottom: "10px" },
    btnWrap: { display: "flex", justifyContent: "space-between", padding: "20px 0" },
  };

  return (
    <Section>
      <h3 style={myStyle.title}>{title}</h3>
      {inputFields.map((item, index) => {
        const myProps = createInputProps({ item, index, fields, setFields })
        if (isEdit && (item.editable === false)) myProps.readOnly = true;
        if (item.inputType === "file") myProps.onChangeInputFile = onChangeInputFile;
        return <FormInput {...props} {...myProps} />;
      })}

      <br /><hr /><br />

      <h4 style={myStyle.subTitle}>Optional Information &nbsp;&nbsp;
        <Button variant="outline-warning" size="sm"
          style={{ fontWeight: "bold", width: "30px" }} onClick={removeInfoItem}>-</Button>
        &nbsp;
        <Button variant="outline-info" size="sm"
          style={{ fontWeight: "bold", width: "30px" }} onClick={addInfoItem}>+</Button>
      </h4>

      {
        optionalInfo.length > 0 &&
        optionalInfo.map((item, index) => {
          const itemKey = Object.keys(item)[0];
          const itemValue = item[itemKey] || "";
          return (
            <InfoItemForm
              key={index}
              index={index}
              onChangeKey={(e) => onChangeInfoItem(e, index)}
              itemKey={itemKey}
              onChangeValue={(e) => onChangeInfoItem(e, index)}
              itemValue={itemValue}
            />
          )
        })
      }

      <div style={myStyle.btnWrap}>
        <Button variant="outline-secondary" onClick={() => props.history.goBack()}>취소</Button>
        <Button
          variant="danger"
          onClick={onSubmit}
          disabled={!validate()}
        >확인</Button>
      </div>
    </Section>

  )
}

/**
 * @fieldname_editable
 */
const InfoItemForm = (props) => {
  return (
    <Form.Group as={Row}>
      <Col sm="2">
        <Form.Control
          type="text"
          id={"itemKey" + props.index}
          name="itemKey"
          size="sm"
          onChange={props.onChangeKey}
          value={props.itemKey}
        />
      </Col>
      <Col sm="10">
        <Form.Control
          type="text"
          id={"itemValue" + props.index}
          name="itemValue"
          size="sm"
          onChange={props.onChangeValue}
          value={props.itemValue}
        />
      </Col>
    </Form.Group>
  )
}