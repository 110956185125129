import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";

import { setPlatform } from "./redux/platform";
import { setUser, resetUsername } from "./redux/user";
import { useSelector, useDispatch } from "react-redux";
import { userGet } from "./lib/apiLib";
import { debug } from "./settings";

import Routes from "./navigation/Routes";
import NavigationBar from "./navigation/NavigationBar";
import Footer from "./pages/Footer";
import { hot } from "react-hot-loader";

function App(props) {
  const { isMobileDevice } = useSelector((state) => state.platform);
  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      try {
        dispatch(setPlatform());

        await Auth.currentSession();
        let cognitoUser = await Auth.currentUserPoolUser();
        if (debug) console.log('cognitoUser', cognitoUser);

        const res = await userGet({ username: cognitoUser.username });
        if (debug) console.log("User:", res);

        if (!res.status) return handleLogout();

        const user = res.result?.Item;

        if (debug) console.log("User:", user);

        if (user.userType !== "admin") return handleLogout();

        dispatch(setUser({ user: user }));
      } catch (e) {
        if (debug) console.log(e);
      } finally { setIsloading(false) }
    }
    onLoad();
  }, []);

  async function handleLogout() {
    await Auth.signOut();
    dispatch(resetUsername());
    if(debug) console.log("Has Signed out...")
    // props.history.push('/login');
  }

  return (
    <div className="App">
      <NavigationBar {...props} handleLogout={handleLogout} />
      {!isLoading && <Routes />}
      {(!isLoading && !isMobileDevice) && <Footer />}
    </div>
  );
}

// export default withRouter(App);
export default withRouter(hot(module)(App));
